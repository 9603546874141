import React from 'react'
import { Formik, Form, useField, ErrorMessage } from 'formik'
import { useHookstate } from '@hookstate/core'
import { object, string } from 'yup'
import { AuthContext } from '../components/auth/auth-provider'
import store from '../store/store'
import { useNavigate } from 'react-router-dom'
import ModalDeprecated from '../components/modal/modal-deprecated'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const ForgetPassword = () => {
	let navigate = useNavigate()
	const { globalError } = useHookstate(store)
	const { resetPassword } = React.useContext(AuthContext)
	const loading = useHookstate(false)
	const error = useHookstate('')
	const hasError = useHookstate(false)
	const initialValues = { email: '' }
	const formValues = useHookstate(initialValues)
	const passwordResetSuccessful = useHookstate(false)
	const { code } = globalError.auth.get()
	if (code === 'auth/email-already-in-use') {
		error.set('email already registered.')
	} else {
		error.set('error resetting password!')
	}
	const onSubmit = async (values) => {
		if (!hasError.get()) {
			loading.set(true)
			error.set('')
			resetPassword(values, () => {
				loading.set(false)
				passwordResetSuccessful.set(true)
			})
		}
	}
	return (
		<ModalDeprecated
			opened={true}
			allowSubmit={!hasError}
			onSubmit={onSubmit}
			onClose={() => {
				navigate(-1)
			}}
			showActionButtons={false}
		>
			<div className="h-50 mt-0 flex items-center justify-center flex-col ">
				{passwordResetSuccessful.get() ? (
					<>
						<div className="p-5">
							<BsFillCheckCircleFill color="orange" size="3em" />
						</div>
						<div>Password Reset requested!</div>
						<div>
							A password reset email has been sent to {formValues.email.get()}.
						</div>
						<div>
							Please check your email for instructions to reset your password.
						</div>
					</>
				) : (
					<Formik
						initialValues={initialValues}
						onSubmit={onSubmit}
						validationSchema={resetPasswordValidation}
					>
						{({ errors }) => {
							hasError.set(Object.keys(errors).length > 0)
							return (
								<Form className="w-3/4 shadow-md rounded px-8 pt-6 pb-8 bg-gray-100">
									<div className="font-medium text-gray-600 pb-4">
										FORGOT PASSWORD?
									</div>
									<Input name="email" placeholder="Enter your email" />
									<div className="flex items-center justify-between">
										<button
											className="bg-orange-500 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
											type="submit"
										>
											Reset Password
										</button>
										<button
											className="bg-gray-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
											onClick={() => {
												navigate('/login')
											}}
										>
											Back
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				)}
			</div>
		</ModalDeprecated>
	)
}

const resetPasswordValidation = object().shape({
	email: string().required('Required').email('Valid email required'),
})

const Input = ({ name, label, ...props }) => {
	const [field, meta] = useField(name)

	return (
		<div className="mb-4">
			{/*<label className="block text-gray-700 text-sm font-bold" for={field.name}>*/}
			{/*  {label}*/}
			{/*</label>*/}
			<input
				className={`${
					meta.error && meta.touched ? 'border-red-500' : ''
				} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline`}
				{...field}
				{...props}
			/>
			<ErrorMessage
				name={field.name}
				component="div"
				className="text-red-500 text-xs"
			/>
		</div>
	)
}

export default ForgetPassword
