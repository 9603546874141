import { getFunctions, httpsCallable } from 'firebase/functions'
import store from '../store/store'
import { ErrorHandler } from '../utils/service-utils'
import {
	EmailAuthProvider,
	getAuth,
	updateProfile,
	reauthenticateWithCredential,
	sendPasswordResetEmail,
	updatePassword,
} from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'

export const saveUserInfo = async (userInfo) => {
	try {
		const db = getFirestore()
		const docRef = doc(db, 'users-info', userInfo['uid'])
		await setDoc(docRef, userInfo, { merge: true })
	} catch (e) {
		console.error('Error writing document: ', e)
	}
}
export const getUsersInfo = async (uid) => {
	try {
		console.log('oooooh!', uid)
		const db = getFirestore()
		const docRef = doc(db, 'users-info', uid)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			store.user.merge(docSnap.data())
			return docSnap.data()
		} else {
			console.log('No such document!')
		}
	} catch (e) {
		console.error(e)
	}
}

export const getCurrentUser = async () => {
	try {
		const auth = getAuth()
		const user = auth.currentUser

		if (user) {
			await user.reload()
		}
		store.user.merge(user)
		return user
	} catch (e) {
		console.error(e)
	}
}

export const updateDisplayName = async (displayName) => {
	try {
		const auth = getAuth()
		const user = auth.currentUser

		if (user) {
			await updateProfile(user, {
				displayName: displayName,
			})
		}
	} catch (e) {
		console.error(e)
	}
}

export const updateUserPhoto = async (url) => {
	try {
		const auth = getAuth()
		const user = auth.currentUser

		if (user && typeof url === 'string' && isValidURL(url)) {
			const usrRes = await updateProfile(user, {
				photoURL: url,
			})
			console.log('photoURL', url)
			store.user.photoURL.set(url)
			const usr = await getUsersInfo(user.uid)

			//TODO:add remember me to local storage
			localStorage.setItem(
				'user',
				JSON.stringify({
					...usr,
					displayName: user.displayName,
					email: user.email,
					emailVerified: user.emailVerified,
					isAuthenticated: !!user,
					anon: false,
					uid: user.uid,
					photoURL: url,
				})
			)
		}
	} catch (e) {
		console.error(e)
	}
}

function isValidURL(string) {
	var res = string.match(
		/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
	)
	return res !== null
}

export const retrieveUserInfo = (onSuccess) => {
	store.user.loading.set(true)
	const retrieveInfo = httpsCallable(getFunctions(), 'users-retrieveUserInfo')
	retrieveInfo()
		.then((result) => {
			if (result.data) {
				store.user.merge(result.data)

				if (onSuccess) {
					onSuccess()
				}
			}

			store.user.loading.set(false)
		})
		.catch((error) => {
			ErrorHandler(error)
		})
}

export const createUserInfo = (data, onSuccess, onFailure) => {
	store.user.loading.set(true)
	const update = httpsCallable(getFunctions(), 'users-createUserInfo')
	update(data)
		.then((result) => {
			store.user.merge(result.data)
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			ErrorHandler(error, onFailure)
		})
}

export const reAuthenticateUserCredentials = async (
	password,
	onSuccess,
	onError
) => {
	try {
		const auth = getAuth()
		const user = auth.currentUser
		if (!user) {
			console.error('User not authenticated')
			onError('User not authenticated')
			return
		}

		const credential = EmailAuthProvider.credential(user.email, password)

		const reauth = await reauthenticateWithCredential(user, credential)

		onSuccess()
	} catch (error) {
		console.error(error)
		onError('Incorrect password')
	}
}

export const resetPassword = async (newPassword, onSuccess, onError) => {
	try {
		if (!newPassword) {
			throw new Error('Email is required')
		}
		const auth = getAuth()
		const user = auth.currentUser
		await updatePassword(user, newPassword)
		if (onSuccess) {
			onSuccess()
		}
	} catch (error) {
		console.error(error)
		onError('Unable to send password reset email')
	}
}
