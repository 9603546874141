/*eslint-disable*/
import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'

export default function Time({ time, string }) {
	const { user } = useHookstate(store)

	return (
		<div className="flex flex-col items-center justify-center">
			<div className="basis-1/4 ">
				<p className="text-sm font-small text-gray-500  md:text-1xl">{time}</p>
			</div>

			<div className="basis-1/2 border-t-2 border-secondary-500">
				<p className="text-[2px] font-small text-gray-500  md:text-xs">
					{string}
				</p>
			</div>
		</div>
	)
}
