import React, { useEffect, useRef } from 'react'
import Layout from '../layouts/index'
import { useParams } from 'react-router-dom'
import { retrieveEvent } from '../services/events'
import { useHookstate } from '@hookstate/core'
import Input from '../components/forms/input/input'
import Button from '../components/forms/button/button'
import { EMAIL_PATTERN, eventTypeConstant } from '../constants'
import { AuthContext } from '../components/auth/auth-provider'
import NotFound from './not-found'
import ActionModal from '../components/modal/action-modal'
import store from '../store/store'
import Loading from '../loading/loading'
import { subscribeToEvent } from '../services/subscriptions'

const statusTextDictionary = {
	success: 'You have successfully joined the event.',
	failed: 'The event may have ended or may have been cancelled.',
}

const statusTitleDictionary = {
	success: 'Great!',
	failed: 'Unable to join event!',
}
export default function JoinEvent() {
	const { user } = useHookstate(store)
	const { anonsignin } = React.useContext(AuthContext)
	const { eventId } = useParams()
	const scrollRevealRef = useRef([])
	const event = useHookstate({})
	const email = useHookstate('')
	const fullName = useHookstate('')
	const errorMessage = useHookstate({})
	const joinComplete = useHookstate(false)
	const loading = useHookstate(false)

	useEffect(() => {
		if (!user.isAuthenticated.get()) {
			loading.set(true)
			anonsignin(() => {
				retrieveEvent(eventId, (e) => {
					event.set(e)
					loading.set(false)
				})
			})
		}
	}, [])

	const onSubmit = () => {
		if (!fullName.get() || !email.get()) {
			if (!email.get() || !EMAIL_PATTERN.test(email.get())) {
				errorMessage.set({
					...errorMessage.get(),
					email: 'Please enter a valid email address.',
				})
			} else if (!fullName.get()) {
				errorMessage.set({
					...errorMessage.get(),
					fullName: 'Please enter your full name.',
				})
			}
		} else {
			errorMessage.set({})
			loading.set(true)
			subscribeToEvent(
				{
					email: email.get(),
					fullName: fullName.get(),
					eventId: event.get().id,
				},
				() => {
					fullName.set('')
					email.set('')
					loading.set(false)
				},
				(message) => {
					errorMessage.set({ error: message })
					loading.set(false)
				}
			)
			joinComplete.set(true)
		}
	}

	if (
		Object.keys(event?.get()).length === 0 ||
		event.get()?.status !== 'active'
	) {
		return (
			<NotFound
				title="Event does not exist"
				msg="Event may have ended or may have been cancelled"
			/>
		)
	}

	const error = errorMessage.get().error
	console.log(loading, '+++')
	return (
		<Layout>
			<ActionModal
				opened={joinComplete.get()}
				text={error ? error : statusTextDictionary['success']}
				title={
					error
						? statusTitleDictionary['failed']
						: statusTitleDictionary['success']
				}
				actionStatus={!error}
			/>
			<>
				<section className="text-center lg:w-full lg:text-left lg:py-20">
					<Loading loading={loading.get()} />
					{event.get()?.eventName && (
						<div className="w-full max-w-6xl px-6 mx-auto hero">
							<div className="relative hero-inner lg:flex">
								<div
									className="pt-10 pb-16 hero-copy lg:pt-16 lg:pr-20"
									// style={{ minWidth: '600px' }}
								>
									<div className="w-full max-w-3xl mx-auto">
										<h1
											ref={(el) => (scrollRevealRef.current[0] = el)}
											className="mt-0 mb-4 text-4xl font-bold is-revealing md:text-5xl capitalize"
										>
											{`Join ${event.get()?.eventName} ${
												event.get()?.eventType === eventTypeConstant.OTHER
													? event?.otherEventType
													: event?.eventType
											}`}
										</h1>
										<p
											ref={(el) => (scrollRevealRef.current[1] = el)}
											className="px-16 prose prose-xl is-revealing md:px-0 text-center"
										>
											Enter your name and email to join
										</p>
									</div>

									<div ref={(el) => (scrollRevealRef.current[2] = el)}>
										<div className="newsletter-form is-revealing max-w-md m-0 mt-8 grid grid-columns-1 md:grid-rows-1 gap-3 ">
											<Input
												type="fullName"
												name="fullName"
												placeholder="Your Fullname"
												onChange={(e) => {
													fullName.set(e)
												}}
												value={fullName.get()}
												errorMessage={errorMessage.get()?.fullName}
											/>

											<Input
												type="email"
												name="email"
												placeholder="Your best email&hellip;"
												onChange={(e) => {
													email.set(e)
												}}
												value={email.get()}
												errorMessage={errorMessage.get()?.email}
											/>

											<div className="control">
												<Button onClick={onSubmit} label="Join" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</section>
			</>
		</Layout>
	)
}
