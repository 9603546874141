import { useHookstate } from '@hookstate/core'
import React from 'react'
import debounce from 'lodash.debounce'
import store from '../../store/store'
import { retrieveSearchableEventsList } from '../../services/events'
import { useNavigate } from 'react-router-dom'

function SearchBar() {
	const { events, event, searchableEvents } = useHookstate(store)
	const searching = useHookstate()
	const navigate = useNavigate()

	const retrieveList = debounce(() => {
		searching.set(true)
		retrieveSearchableEventsList(eventSearch.get(), () => {
			searching.set(false)
		})
	}, 1000)

	const eventSearch = useHookstate()

	return (
		<div className="h-24 p-4 w-3/5">
			<input
				type="search"
				value={eventSearch.get()}
				onChange={(e) => {
					eventSearch.set(e.target.value)
					if (eventSearch.get()?.length > 2) {
						retrieveList()
					}
				}}
				className="w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
				placeholder="Search Public Events..."
			/>
			{searching.get() ? (
				<div className="text-sm text-gray-500">Searching...</div>
			) : null}
			{eventSearch.get()?.length > 2 && (
				<ul className="z-50 relative list-item  bg-white border border-gray-100 w-full mt-2">
					{searchableEvents.get()?.length === 0 ? (
						<div className="text-sm text-gray-500">
							No private or public events with this name. Please reach out to
							event owner.
						</div>
					) : null}
					{searchableEvents.get()?.map((eventObject, index) => (
						<>
							{' '}
							<li
								key={`event${index}`}
								className="z-50 p-3 pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
							>
								<b>{eventObject.displayName || eventObject.eventName}</b>
								<span className="italic text-sm">
									{' '}
									- {eventObject.eventAccess}
								</span>
								<button
									className="bg-white underline text-blue-600/100 hover:text-orange-800 "
									onClick={(e) => {
										e.preventDefault()
										navigate(`/events/${eventObject.id}`)
									}}
								>
									view event
								</button>
							</li>
						</>
					))}

					{/* <li className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
						<b>Gar</b>da - Veneto - Italië
					</li>
					<li className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
						<b>Gar</b>da Hotel - Italië
					</li>
					<li className="pl-8 pr-2 py-1 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
						<b>Gar</b>dena Resort - Italië
					</li> */}
				</ul>
			)}
		</div>
	)
}

SearchBar.propTypes = {}

export default SearchBar
