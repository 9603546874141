import React, { useState, useEffect } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { useFormik } from 'formik'
import { useHookstate } from '@hookstate/core'
import { accountProfileSchema } from '../../utils/form-utils'
import Input from '../../components/forms/input/formik-input'
import Card from '../../components/cards/card'
import { uploadFile } from '../../utils/storage-util'
import store from '../../store/store'
import {
	getCurrentUser,
	getUsersInfo,
	saveUserInfo,
	updateDisplayName,
	updateUserPhoto,
} from '../../services/users'
import { useNavigate } from 'react-router-dom'

const ChangeProfileScreen = () => {
	const { user, alert } = useHookstate(store)
	const navigate = useNavigate()
	const submitting = useHookstate(false)
	const uploading = useHookstate(false)
	const [selectedImage, setSelectedImage] = useState(null)
	const [imagePreview, setImagePreview] = useState(null)
	useEffect(() => {
		getCurrentUserInfo()
	}, [])

	const handleImagePreview = (event) => {
		event.preventDefault()
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0]
			setSelectedImage(img)
			setImagePreview(URL.createObjectURL(img))
			uploadImage(img)
		}
	}

	const getCurrentUserInfo = async () => {
		const u = await getCurrentUser()

		if (u && u.uid) {
			const userInfo = await getUsersInfo(u.uid)

			console.log('u', u, userInfo)
		}
	}

	const uploadImage = async (e) => {
		if (!e) return
		uploading.set(true)
		uploadFile({
			path: e,
			uid: user.uid.get(),
			onUploadSuccess: (url) => {
				updateUserPhoto(url)
				user.photoURL.set(url)
				uploading.set(false)
				alert.set({
					show: true,
					data: {
						message: `Profile image uploaded successfully`,
						type: 'success',
					},
				})
			},
			onError: (e) => {
				console.log('Upload error', e)
				// Handle error here
				uploading.set(false)
				alert.set({
					show: true,
					data: {
						message: `Unable to upload profile image`,
						type: 'error',
					},
				})
			},
		})
	}

	const formik = useFormik({
		initialValues: {
			email: user.email.get(),
			displayName: user.displayName.get(),
			uid: user.uid.get(),
			bio: user.bio.get(),
			photoURL: user.photoURL.get(),
		},
		accountProfileSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (data, { resetForm }) => {
			submitting.set(true)

			try {
				let updated = false
				if (
					data.displayName &&
					data.displayName !== user.displayName.get() &&
					data.displayName.length > 0
				) {
					await updateDisplayName(data.displayName) // Implement updateDisplayName function
					updated = true
				}
				if (data.bio && data.bio.length > 0) {
					await saveUserInfo({
						uid: user.uid.get(),
						bio: data.bio,
						updated: Date.now(),
					})
					updated = true
				}
				if (updated) {
					await getCurrentUserInfo()
				}
				submitting.set(false)
				alert.set({
					show: true,
					data: {
						message: `Profile updated successfully`,
						type: 'success',
					},
				})
				navigate('/home')
			} catch (e) {
				console.error(e)
				submitting.set(false)
				alert.set({
					show: true,
					data: {
						message: `Unable to update profile`,
						type: 'error',
					},
				})
			}
		},
	})

	return (
		<div className="container p-4 flex  flex-col items-start justify-center">
			<form
				className="flex flex-col items-start justify-center w-full"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="w-8/12  text-sm text-blueGray-400 mt-4 grid grid-rows-1 gap-2 md:gap-1">
					<div className="flex flex-col items-center justify-center mb-4 relative">
						<input
							type="file"
							accept="image/*"
							className="hidden"
							id="imageUpload"
							onChange={handleImagePreview}
						/>
						<div
							className="w-28 h-28 object-cover rounded-full align-middle border-none relative cursor-pointer"
							onClick={() => document.getElementById('imageUpload').click()}
						>
							<img
								alt="..."
								className="w-full h-full object-cover rounded-full"
								src={
									imagePreview ||
									user.photoURL.get() ||
									require('../../assets/img/team-1-800x800.jpg')
								}
							/>
							<FaPencilAlt className="absolute bottom-0 right-0 mb-2 mr-2 text-white" />
						</div>
						<button
							className={`${
								uploading.get() ? 'bg-orange-200' : 'bg-orange-500'
							} w-5/12 mt-4 bg-orange-500 text-white py-2 px-4 rounded`}
							onClick={(e) => {
								e.preventDefault()
								document.getElementById('imageUpload').click()
							}}
						>
							{uploading.get() ? 'Uploading Profile...' : 'Upload Picture'}
						</button>
					</div>
					<div className="w-full">
						<Input
							id="displayName"
							name="displayName"
							handleChange={formik.handleChange}
							value={formik.values.displayName}
							errorMessage={formik.errors.displayName}
							type="text"
							placeholder="Full Name*"
						/>
					</div>

					<div className="w-full">
						<Input
							id="email"
							name="email"
							handleChange={formik.handleChange}
							value={formik.values.email}
							errorMessage={formik.errors.email}
							type="text"
							placeholder="Email*"
						/>
					</div>

					<div className="w-full">
						<Input
							id="bio"
							name="bio"
							handleChange={formik.handleChange}
							value={formik.values.bio}
							placeholder="Bio (Optional)"
							multiline
							rows={5}
						/>
					</div>

					<div className="flex items-center justify-center">
						<button
							type="submit"
							className={`${
								submitting.get() ? 'bg-orange-200' : 'bg-orange-500'
							} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline rounded`}
						>
							{submitting.get() ? 'Updating Profile...' : 'Done'}
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default ChangeProfileScreen
