import React from 'react'
import PropTypes from 'prop-types'

const Input = ({
                 placeholderForEmpty,
                 readOnly,
                  value,
                 placeholder,
                 onChange,
                 handleChange,
                 errorMessage,
                 multiline,
                 showCount,
                 className,
                 ...rest
               }) => {
  if (readOnly) {
    return (
      <>
        {value}
      </>
    )
  }
  const multilineTextBox =
    <textarea
      {...rest}
      aria-placeholder={placeholder}
      className="resize-none w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  const singleLineTextBox = (
    <div className="flex-grow flex-shrink">
      <input
        {...rest}
        aria-placeholder={placeholder}
        className={`w-full px-6 py-6 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic ${className}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
      {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}
    </div>)
  return (
    <>
      {multiline ? multilineTextBox : singleLineTextBox}
    </>
  )
}

Input.defaultProps = {
  value: '',
  placeholder: '',
  onChange() {
  },
  handleChange() {
  },
  type: 'text',
  errorMessage: '',
  name: '',
  multiline: false,
  readOnly: false
}

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default Input;
