import { useHookstate } from '@hookstate/core'
import React from 'react'
import debounce from 'lodash.debounce'
import store from '../../store/store'
import { retrieveSearchableEventsList } from '../../services/events'
import { useNavigate } from 'react-router-dom'
import Loader from '../loader'

function SearchBarWithButton({ searchEvents }) {
	const { events, event, searchableEvents } = useHookstate(store)
	const [searching, setSearching] = React.useState(false)
	const [search, setSearch] = React.useState('')
	const navigate = useNavigate()

	const searchEvent = async () => {
		if (search.length > 2) {
			setSearching(true)
			await searchEvents(search)
			setSearching(false)
		}
	}

	const eventSearch = useHookstate()
	return (
		<div className="h-24 p-4 w-2/4 ml-auto  flex items-center justify-center">
			<input
				type="search"
				value={eventSearch.get()}
				onChange={(e) => {
					setSearch(e.target.value)
				}}
				className="w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
				placeholder="Search Public Events..."
			/>

			<button
				disabled={searching}
				className={`bg-secondary-500 font-normal text-white rounded-none`}
				onClick={() => {
					searchEvent()
				}}
			>
				{searching ? <Loader style={'h-5 w-5 fill-white'} /> : 'SEARCH'}
			</button>
		</div>
	)
}

SearchBarWithButton.propTypes = {}

export default SearchBarWithButton
