import React, { useEffect } from 'react'
import Input from '../forms/input/formik-input'
import { useHookstate } from '@hookstate/core'
import { useFormik } from 'formik'
import { updateEvent } from '../../services/events'
import { getChangedValues, validationSchema } from '../../utils/form-utils'
import { useAlert } from 'react-alert'
import { updateSubscription } from '../../services/subscriptions'
import EditModeDatePicker from '../forms/date-picker/editmode-date-picker'
import Dropdown from '../forms/select/select'
import TimeSelect from '../forms/select/time-select'
import InLineInput from '../forms/input/inline-input'
import {
	eventAccessMap,
	eventFrequencyMap,
	eventTypeConstant,
	eventTypeMap,
	timeOptions,
} from '../../constants'
import PlacesTypeAhead from '../forms/select/places-select'
import store from '../../store/store'

const dateOptions = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
}

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]

function make2digit(i) {
	const min = i.toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false,
	})
	return min
}
const physical = { value: 'physical', label: 'In Person' }
const virtual = { value: 'virtual', label: 'Virtual' }
const hybrid = { value: 'hybrid', label: 'Hybrid' }
const eventOptionsMap = { physical, virtual, hybrid }
const eventOptions = [physical, virtual, hybrid]

function EventDetail({ onCancel, onUpdate, children }) {
	const updatingEvent = useHookstate(false)
	const alert = useAlert()
	const [placeLocation, setPlaceLocation] = React.useState(null)

	const { event, events, user, isEventDeleted } = useHookstate(store)
	const { selectedEvent, editMode, guestEditMode } = event.get()
	const eventObject = events[selectedEvent]?.get({ noproxy: true })
	const minutes = []

	const formik = useFormik({
		initialValues: {
			...eventObject,
			selectedOption: {},
		},
		// enableReinitialize: true,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (data, { resetForm }) => {
			updatingEvent.set(true)
			const obj = getChangedValues(data, eventObject)
			if (eventObject.subscriberUid === user.uid.get()) {
				if (obj.displayName) {
					updateSubscription(
						{
							eventId: eventObject.id,
							email: user.email.get(),
							displayName: data.displayName,
						},
						() => {
							onUpdate()
							updatingEvent.set(false)
							resetForm()
							alert.show('Event Successfully Updated', { type: 'success' })
						},
						(message) => {
							updatingEvent.set(false)
							console.log(
								'🚀 ~ file: eventObject-detail.js ~ line 70 ~ EventDetail ~ message',
								message
							)
						}
					)
				}
			} else {
				if (Object.keys(obj).length > 0) {
					const change = {
						id: data.id,
						eventName: data.eventName,
						uid: data.uid,
						...obj,
					}
					updateEvent(
						change,
						(data) => {
							onUpdate()
							updatingEvent.set(false)
							resetForm()
							alert.show('Event Successfully Updated', { type: 'success' })
						},
						() => {
							alert.show('Unable to update eventObject', { type: 'error' })
						}
					)
				}
			}
			// updatingEvent.set(false)
		},
	})

	useEffect(() => {
		formik.setValues({ ...eventObject })
	}, [eventObject])
	useEffect(() => {
		setPlaceLocation({
			label: eventObject?.eventLocation,
			value: eventObject?.locationDetails,
		})

		const selectedOption = formik.values.selectedOption
		selectedOption['startTime'] = {
			label: eventObject?.startTime,
			value: eventObject?.startTime,
		}
		selectedOption['endTime'] = {
			label: eventObject?.endTime,
			value: eventObject?.endTime,
		}
		formik.setFieldValue('selectedOption', selectedOption)
	}, [eventObject])

	for (let i = 0; i < 60; i++) {
		const min = make2digit(i)
		minutes.push(
			<option key={`minute${min}`} value={`${min}`}>
				{min}
			</option>
		)
	}
	const meetingUrl = formik.values.meetingUrl
	const m =
		meetingUrl?.includes('https') || meetingUrl?.includes('http')
			? meetingUrl
			: `https://${meetingUrl}`
	let eventNameText = meetingUrl
	const eventMode = formik.values.eventMode
	const isPhysical = eventMode === 'physical'
	const isHybrid = eventMode === 'hybrid'
	const isVirtual = eventMode === 'virtual'
	if (isPhysical || isHybrid) {
		eventNameText = [
			formik.values.street,
			formik.values.city,
			formik.values.state,
			formik.values.zipcode,
			formik.values.country,
		]
			.filter(Boolean)
			.join(', ')
	}
	let eventNameHtml
	let eventModeHtml = (
		<div className="grid grid-cols-2 gap-2 my-5 ">
			<div className="flex flex-row">
				<div
					className={`mt-1 w-3 h-3 ${
						eventMode === 'virtual' || eventMode === 'hybrid'
							? 'bg-green-500'
							: 'bg-gray-300'
					}`}
				></div>
				<div className="mx-2">Virtual</div>
			</div>
			<div className="flex flex-row">
				<div
					className={`mt-1 w-3 h-3 ${
						eventMode === 'physical' || eventMode === 'hybrid'
							? 'bg-green-500'
							: 'bg-gray-300'
					}`}
				></div>
				<div className="mx-2">In Person</div>
			</div>
		</div>
	)
	const shouldEdit = editMode && eventObject.uid === user.uid.get()
	if (shouldEdit) {
		eventModeHtml = (
			<>
				<div className="font-bold text-orange-400 ">Event Mode</div>
				<div className="w-full">
					<Dropdown
						readOnly={false}
						selectedOption={eventOptionsMap[eventMode]}
						placeholder="Choose Event Mode*"
						options={eventOptions}
						onChange={(option) => {
							formik.setFieldValue('eventMode', option.value)
							const selectedOption = formik.values.selectedOption
							selectedOption['eventMode'] = option
							formik.setFieldValue('selectedOption', selectedOption)
						}}
					/>
				</div>
			</>
		)
	}
	let eventAccessibilityHtml
	if (shouldEdit) {
		eventAccessibilityHtml = (
			<>
				<div className="font-bold text-orange-400 ">Event Accessibility</div>
				<div className="w-full">
					<Dropdown
						readOnly={false}
						selectedOption={eventAccessMap[formik.values?.eventAccess]}
						placeholder="Choose Event Accessibility*"
						options={[
							{ value: 'public', label: 'Public' },
							{
								value: 'protected',
								label: 'Protected - Not Searchable',
							},
							{
								value: 'strictlyByInvite',
								label: 'Private - Strictly By Invitation',
							},
						]}
						onChange={(option) => {
							formik.setFieldValue('eventAccess', option.value)
							const selectedOption = formik.values.selectedOption
							selectedOption['eventAccess'] = option
							formik.setFieldValue('selectedOption', selectedOption)
						}}
					/>
				</div>
			</>
		)
	}

	let eventFrequencyHtml
	if (shouldEdit) {
		eventFrequencyHtml = (
			<>
				<div className="font-bold text-orange-400 ">Event Frequency</div>
				<div className="w-full">
					<Dropdown
						readOnly={false}
						selectedOption={eventFrequencyMap[formik.values?.eventFrequency]}
						errorMessage={formik.errors.eventFrequency}
						placeholder="Choose Event Frequency*"
						options={[
							{ value: 'one-time', label: 'One-time Event' },
							{ value: 'recurring', label: 'Recurring Event' },
						]}
						onChange={(option) => {
							formik.setFieldValue('eventFrequency', option.value)
							const selectedOption = formik.values.selectedOption
							selectedOption['eventFrequency'] = option
							formik.setFieldValue('selectedOption', selectedOption)
						}}
					/>
				</div>
			</>
		)
	}

	let eventTypeHtml
	if (shouldEdit) {
		eventTypeHtml = (
			<>
				<div className="font-bold text-orange-400 ">Event Type</div>
				<div className="w-full">
					<Dropdown
						readOnly={false}
						selectedOption={eventTypeMap[formik.values?.eventType]}
						errorMessage={formik.errors.eventType}
						placeholder="Choose Event Type*"
						options={[
							{ value: 'party', label: 'Party' },
							{ value: 'auction', label: 'Auction' },
							{ value: 'other', label: 'Other' },
						]}
						onChange={(option) => {
							formik.setFieldValue('eventType', option.value)
							const selectedOption = formik.values.selectedOption
							selectedOption['eventType'] = option
							formik.setFieldValue('selectedOption', selectedOption)
						}}
					/>
				</div>
			</>
		)
	}

	let otherEventTypeHtml
	if (shouldEdit) {
		otherEventTypeHtml = (
			<>
				{formik?.values?.eventType === eventTypeConstant.OTHER && (
					<div className="w-full">
						<div className="font-bold text-orange-400 ">Other Event Type</div>
						<Input
							id="otherEventType"
							name="otherEventType"
							placeholder="Enter other eventObject type"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								if (value) {
									formik.setFieldValue('otherEventType', value)
								}
							}}
							value={formik.values.otherEventType}
							errorMessage={formik.errors.otherEventType}
						/>
					</div>
				)}
			</>
		)
	}

	let eventNameId = 'eventName'
	let eventNameValue = formik.values.eventName
	let eventNameError = formik.errors.eventName
	if (eventObject?.isHost === false) {
		eventNameId = 'displayName'
		eventNameValue = formik.values.displayName
		eventNameError = formik.errors.displayName
	}

	let eventAddressHtml
	if (isPhysical || isHybrid) {
		eventAddressHtml = (
			<Input
				readOnly={!editMode}
				value={eventNameText}
				handleChange={() => {}}
			/>
		)
		if (shouldEdit) {
			eventAddressHtml = (
				<>
					<div className="w-full">
						<Input
							id="street"
							name="street"
							handleChange={formik.handleChange}
							value={formik.values.street}
							errorMessage={formik.errors.street}
							type="text"
							placeholder="Event Street"
						/>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
						<Input
							id="city"
							name="city"
							placeholder="Event City"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								formik.setFieldValue('city', value)
							}}
							value={formik.values.city}
							errorMessage={formik.errors.city}
						/>
						<Input
							id="state"
							name="state"
							placeholder="Event State"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								formik.setFieldValue('state', value)
							}}
							value={formik.values.state}
							errorMessage={formik.errors.state}
						/>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
						<Input
							id="zipcode"
							name="zipcode"
							placeholder="Event Zip Code"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								formik.setFieldValue('zipcode', value)
							}}
							value={formik.values.zipcode}
							errorMessage={formik.errors.zipcode}
						/>
						<Input
							id="country"
							name="country"
							placeholder="Event Country"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								formik.setFieldValue('country', value)
							}}
							value={formik.values.country}
							errorMessage={formik.errors.country}
						/>
					</div>
				</>
			)
		}
	}
	//TODO: create util to not show comma if field is empty
	const hybridVenue = isHybrid && (
		<div>{`${eventObject.street}, ${eventObject.city}, ${eventObject.state}, ${eventObject.zipcode}`}</div>
	)

	const venueHtml = (
		<div className="grid grid-rows-1">
			<div className="font-bold text-orange-400">Venue:</div>
			<div className="text-2xl text-black">
				<>{shouldEdit ? eventAddressHtml : null}</>
				{(isVirtual || isHybrid) &&
					(shouldEdit ? (
						<Input
							id="meetingUrl"
							name="meetingUrl"
							placeholder="Meeting Url (Optional)"
							type="text"
							handleChange={(e) => {
								e.preventDefault()
								const { value } = e.target
								if (value) {
									formik.setFieldValue('meetingUrl', value)
								}
							}}
							value={meetingUrl}
							errorMessage={formik.errors.meetingUrl}
						/>
					) : (
						<div>
							{hybridVenue}
							<div className="text-blue-300 underline">
								{meetingUrl ? <a href={m}>Online (click to join)</a> : 'Online'}
							</div>
						</div>
					))}
			</div>
		</div>
	)

	let displayNameId = 'displayName'
	let displayNameValue = formik.values.displayName
	let displayNameError = formik.errors.displayName
	if (eventObject?.isHost === false) {
		displayNameId = 'displayName'
		displayNameValue = formik.values.displayName
		displayNameError = formik.errors.displayName
	}
	//let editDisplayName
	const editDisplayName = (
		<div className="mt-10">
			<InLineInput
				id={displayNameId}
				name={displayNameId}
				readOnly={false}
				handleChange={formik.handleChange}
				value={displayNameValue}
				errorMessage={displayNameError}
				type="text"
				submitting={updatingEvent.get()}
				placeholder="Event Display Name"
				cancelAction={() => {
					// updatedEvent.set({})
					formik.resetForm()
					onCancel()
				}}
			/>
		</div>
	)
	console.log('___DATE___', formik.values?.date)
	const dateHtml = (
		<div className="flex-1 z-[60]">
			<div className="font-bold text-orange-400">Date</div>
			<div className="text-2xl text-black">
				<EditModeDatePicker
					readOnly={!editMode || eventObject.uid !== user.uid.get()}
					errorMessage={formik.errors.date}
					required
					value={formik.values?.date}
					onSelect={(dateObject) => {
						formik.setFieldValue('date', dateObject?.date)
						formik.setFieldValue('jsonDate', dateObject?.jsonDate)
					}}
				/>
			</div>
		</div>
	)

	const endDateHtml = formik.values.eventFrequency === 'recurring' && (
		<div className="flex-1 z-[60]">
			<div className="font-bold text-orange-400">End Date</div>
			<div className="text-2xl text-black">
				<EditModeDatePicker
					readOnly={!editMode || eventObject.uid !== user.uid.get()}
					errorMessage={formik.errors.endDate}
					required
					excludeDateFrom={formik.values.date}
					value={formik.values?.endDate}
					onSelect={(dateObject) => {
						formik.setFieldValue('endDate', dateObject?.date)
						formik.setFieldValue('endDateJsonDate', dateObject?.jsonDate)
					}}
				/>
			</div>
		</div>
	)

	let eventLocationHTML
	if (shouldEdit) {
		eventLocationHTML = eventObject?.unixTimestamp ? (
			<div className="w-full">
				<PlacesTypeAhead
					value={placeLocation}
					date={formik.values.date}
					placeholder="Event Location*"
					errorMessage={formik.errors.eventLocation}
					onSelect={(s) => {
						formik.setFieldValue('eventLocation', s.label)
						formik.setFieldValue('locationDetails', s.locationDetails)
						setPlaceLocation(s)
					}}
				/>
			</div>
		) : null
	}

	const startTimeHtml = (
		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-1">
			<div className="col-span-1">
				<div className="w-full">
					<div className="font-bold text-orange-400">Start Time</div>
					<Dropdown
						readOnly={!editMode || eventObject.uid !== user.uid.get()}
						selectedOption={formik.values.selectedOption?.startTime}
						errorMessage={formik.errors.startTime}
						placeholder="Start Time*"
						options={timeOptions}
						onChange={(option) => {
							formik.setFieldValue('startTime', option.value)
							const selectedOption = formik.values.selectedOption
							selectedOption['startTime'] = option
							formik.setFieldValue('selectedOption', selectedOption)
						}}
					/>
				</div>
			</div>

			<div className="col-span-1">
				<div className="col-span-1">
					<div className="w-full">
						<div className="font-bold text-orange-400">End Time</div>
						<Dropdown
							readOnly={!editMode || eventObject.uid !== user.uid.get()}
							selectedOption={formik.values.selectedOption?.endTime}
							placeholder="End Time"
							options={timeOptions}
							onChange={(option) => {
								formik.setFieldValue('endTime', option.value)
								const selectedOption = formik.values.selectedOption
								selectedOption['endTime'] = option
								formik.setFieldValue('selectedOption', selectedOption)
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)

	const eventDetailsHtml = (
		<div className="grid grid-rows-1">
			{formik.values.eventDetails && (
				<div className="font-bold text-orange-400">Event Details:</div>
			)}
			<div className="w-full text-lg">
				<Input
					readOnly={!editMode || eventObject.uid !== user.uid.get()}
					id="eventDetails"
					name="eventDetails"
					placeholder="Event Details (Optional)"
					handleChange={formik.handleChange}
					value={formik.values.eventDetails}
					errorMessage={formik.errors.eventDetails}
					multiline
					rows={5}
				/>
			</div>
		</div>
	)

	const contactDetailsHtml = (
		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-1 text-black text-lg">
			<div className="font-bold text-orange-400 ">Phone:</div>
			<div className="font-bold text-orange-400 ">Email:</div>
			<div className="w-full">
				<Input
					readOnly={!editMode || eventObject.uid !== user.uid.get()}
					id="phone"
					name="phone"
					placeholder="Phone*"
					type="text"
					pattern="[0-9]*"
					handleChange={(e) => {
						e.preventDefault()
						const { value } = e.target
						const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
						if (regex.test(value.toString())) {
							formik.setFieldValue('phone', value)
						}
					}}
					value={formik.values.phone}
					errorMessage={formik.errors.phone}
				/>
			</div>
			<div className="w-full">
				<Input
					readOnly={!editMode || eventObject.uid !== user.uid.get()}
					id="email"
					name="email"
					handleChange={formik.handleChange}
					value={formik.values.email}
					errorMessage={formik.errors.email}
					placeholder="Email Address*"
				/>
			</div>
		</div>
	)

	let ownerEditableFormFields
	// if (eventObject?.isHost !== false) {
	ownerEditableFormFields = (
		<>
			{eventModeHtml}

			{children}
			{venueHtml}
			{eventAccessibilityHtml}
			{eventFrequencyHtml}

			<div className="flex gap-1">
				{dateHtml}
				{endDateHtml}
			</div>

			{startTimeHtml}
			{eventLocationHTML}
			{eventTypeHtml}
			{otherEventTypeHtml}
			{contactDetailsHtml}
			{eventDetailsHtml}
		</>
	)
	// }
	if (editMode) {
		eventNameHtml = (
			<Input
				id={eventNameId}
				name={eventNameId}
				readOnly={false}
				handleChange={formik.handleChange}
				value={eventNameValue}
				errorMessage={eventNameError}
				type="text"
				placeholder="Event Name*"
			/>
		)
	}

	let nameToEditHtml = eventNameHtml
	if (guestEditMode && eventObject.uid !== user.uid.get()) {
		nameToEditHtml = editDisplayName
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="w-full text-sm text-blueGray-400 grid grid-cols-1 gap-2 w-full mx-1">
				{/* {nameToEdit} */}
				{nameToEditHtml}
				{ownerEditableFormFields}
				<div className={`${!editMode ? 'hidden' : ''} text-center w-full`}>
					<button
						type="button"
						onClick={() => {
							// updatedEvent.set({})
							formik.resetForm()
							onCancel()
						}}
					>
						Cancel
					</button>
					<button
						type="submit"
						className={`${
							updatingEvent.get() ? 'bg-orange-200' : 'bg-orange-500'
						} w-5/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
					>
						{updatingEvent.get() ? 'Updating Event...' : 'Update Event'}
					</button>
				</div>
			</div>
		</form>
	)
}

EventDetail.defaultProps = {
	editMode: false,
	user: null,
}

EventDetail.propTypes = {}

export default EventDetail
