import React, { useEffect, useState } from 'react'
import { BiMoney } from 'react-icons/bi'

const PaymentACHCard = ({ paymentMethod, type = '', guestBalance }) => {
	const [cardStyle, setCardStyle] = useState('bg-gray-100') // Default Tailwind class

	useEffect(() => {
		updateCardStyle()
	}, [paymentMethod, type])

	const updateCardStyle = () => {
		let colorClass = 'bg-gray-100' // Default background color class

		if (paymentMethod['defaultDebit'] === true && type === 'debit') {
			colorClass = 'bg-gray-100' // Blue background color class
		} else if (paymentMethod['defaultCredit'] === true && type === 'credit') {
			colorClass = 'bg-gray-100' // Green background color class
		}

		setCardStyle(colorClass)
	}
	const { balances = {} } = paymentMethod
	const { iso_currency_code } = balances

	return (
		<div className={`w-full p-4 rounded shadow-lg ${cardStyle} min-w-[600px]`}>
			<div className="flex items-center justify-between mb-4">
				<span className="text-orange-500 text-xl font-bold">{`${paymentMethod['name']}`}</span>

				<BiMoney size={50} />
			</div>
			<div className="text-orange-500 text-lg mb-4 w-full flex flex-row items-center justify-center">
				<p>{` ** ${paymentMethod['mask']}`}</p>
			</div>
			<div className="flex items-center justify-between">
				<span className="text-orange-500 text-lg">
					Balance: $ {guestBalance}
				</span>
				{/* Add an icon or anything you like on the bottom-right corner */}
				{/* Example: <i className="fas fa-credit-card text-orange-500"></i> */}
				<span className="text-orange-500 text-xl font-bold">{`${iso_currency_code}`}</span>
			</div>
		</div>
	)
}

export default PaymentACHCard
