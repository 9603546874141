import { useHookstate } from '@hookstate/core'
import React from 'react'
import { useAlert } from 'react-alert'
import store from '../../store/store'
import Card from './card'
import { isOwner } from '../../utils/event-utils'
import {
	changeTimeZone,
	convertTime12to24,
	dateWithTimeZone,
} from '../../utils/date-utils'
import { useNavigate } from 'react-router-dom'
export default function CardManageEvents() {
	const { events, event, user } = useHookstate(store)
	const eventSearch = useHookstate('')
	const alert = useAlert()
	const navigate = useNavigate()

	return (
		<div className="md:max-w-prose text-gray-500">
			<Card title="Manage Events" loading={event.loading.get()}>
				<div className="grid grid-cols-1 my-10 capitalize">
					{/* TODO: Add logic if events are more then 10 show */}

					{!events.get() || events.get()?.length >= 1 ? (
						<input
							type="search"
							value={eventSearch.get()}
							onChange={(e) => {
								eventSearch.set(e.target.value)
							}}
							className="w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
							placeholder="Search My Events..."
						/>
					) : null}
					{!events.get() || events.get()?.length === 0 ? (
						<>There are no events yet.</>
					) : null}
					{events
						.get()
						?.filter((el) => {
							if (eventSearch.get() !== '') {
								return el.eventName
									?.toLowerCase()
									.startsWith(eventSearch.get()?.toLowerCase())
							} else {
								return el
							}
						})
						?.map((eventObject, index) => {
							const isEventHost = eventObject?.isHost
							return (
								<div
									key={`event${index}`}
									onClick={(e) => {
										e.preventDefault()
										event.selectedEvent.set(index)
										event.editMode.set(false)
									}}
									className={`py-3 px-2 hover:text-white hover:bg-gray-400 border-t-2 border-solid
              ${
								index === event.selectedEvent.get()
									? ' text-white bg-gray-400 '
									: ''
							}
              ${events.get().length - 1 === index ? 'border-b-2' : ''}`}
								>
									<div>
										{/* <div className="flex space-x-4 ..."> */}
										{/* <div className="text-base">
											{/* TODO:icons for myEvent and invited event */}
										{/* {eventObject.uid === user.uid ? (
												<MdEvent size={20} />
											) : (
												<MdEventAvailable size={20} />
											)} */}
										{/* </div>
										<div className="text-base ">
											{eventObject.displayName || eventObject.eventName}
										</div>
										<div className="text-base ">

										</div>

									</div>  */}

										{eventObject.displayName || eventObject.eventName}
										<button
											className={`${
												isEventHost ? 'bg-sky-500' : 'bg-orange-500'
											} hover:bg-green-500 text-white font-bold py-2 px-3 rounded float-right`}
											onClick={() => {
												console.log('onClick: Joining Event', eventObject)
												const now = changeTimeZone(
													new Date(),
													eventObject.startTimeZone
												)

												const eventDateArray = eventObject.date.split('-')
												const eventTime = convertTime12to24(
													eventObject.startTime
												)
												const eventDate = dateWithTimeZone(
													eventObject.startTimeZone,
													eventDateArray[0],
													eventDateArray[1],
													eventDateArray[2],
													eventTime?.hours,
													eventTime?.minutes
												)

												console.log('++', eventDate, now)
												let message
												if (eventDate > now) {
													message = `${eventObject.eventName} has not started!`
													alert.show(message, {
														info: 'success',
														customStyle: {
															backgroundColor: isEventHost
																? 'bg-sky-500'
																: 'bg-orange-500',
														},
													})
												}

												//TODO: add logic for active event routing
												navigate(`/events/${eventObject.id}`)
											}}
										>
											{isEventHost ? 'Start Event' : 'Join Event'}
										</button>
									</div>
								</div>
							)
						})}
				</div>
			</Card>
		</div>
	)
}

CardManageEvents.defaultProps = {}

CardManageEvents.propTypes = {}
