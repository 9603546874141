import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'

import { retrieveEvents } from '../../services/events'
import DataTable from '../DataTable'
import SearchBarWithButton from '../searchBarWithButton'
import EditModeDatePicker from '../forms/date-picker/editmode-date-picker'
import { FiChevronLeft, FiLoader, FiChevronRight } from 'react-icons/fi'
import {
	retrievePaginatedSubscriptions,
	retrieveSubscriptionsByDate,
} from '../../services/subscriptions'

export default function AttendedEvents() {
	const { user, subscriptions, isEventDeleted } = useHookstate(store)
	const emailVerified = user.emailVerified.get()
	const { data, paginate, loading } = subscriptions.get({ noproxy: true })
	const [limit, setLimit] = React.useState(10)
	const [filter, setFilter] = React.useState({})
	const [dateFrom, setDateFrom] = React.useState()
	const [dateTo, setDateTo] = React.useState()

	const next = () => {
		retrievePaginatedSubscriptions({
			email: user.email.get(),
			paginate: {
				limit,
				orderBy: 'unixTimestamp',
				orderDirection: 'desc',
				next: paginate.next,
			},
		})
	}

	const previous = () => {}

	const searchByDate = () => {
		retrieveSubscriptionsByDate({ options: { dateFrom, dateTo } })
	}

	const searchEvents = async (text) => {
		retrievePaginatedSubscriptions({
			paginate: {
				limit,
				orderBy: 'eventName',
				orderDirection: 'desc',
				searchTerm: text,
			},
			email: user.email.get(),
		})
	}

	return (
		<>
			<div className="flex fex-col justify-between w-full">
				<div className="h-24 p-4 w-1/2  flex items-center justify-center w-full">
					<EditModeDatePicker
						placeholder={'Range from'}
						allowPastDate={true}
						required
						value={''}
						onSelect={(dateObject) => {
							setDateFrom(dateObject.date)
						}}
					/>

					<span className="mx-3">-</span>

					<EditModeDatePicker
						placeholder={'Range to'}
						allowPastDate={true}
						excludeDateFrom={dateFrom}
						required
						value={''}
						onSelect={(dateObject) => {
							setDateTo(dateObject.date)
						}}
					/>
					<button
						className={`bg-green-500 font-normal text-white rounded-none`}
						onClick={() => {
							searchByDate()
						}}
					>
						Export Statement
					</button>
				</div>

				<SearchBarWithButton searchEvents={searchEvents} />
			</div>
			<div></div>
			<DataTable loading={loading} data={data} />
			{data.length < limit ? null : (
				<div className="flex mt-10 justify-end pr-10">
					<button
						onClick={previous}
						disabled={!paginate?.previous}
						className="ml-10"
					>
						<FiChevronLeft />
					</button>

					<button onClick={next} disabled={!paginate?.next} className="ml-10">
						<FiChevronRight />
					</button>
				</div>
			)}
		</>
	)
}
