import React from 'react'
import PropTypes from 'prop-types'
import { FaSpinner } from 'react-icons/fa'

const Card = ({ item, tab, setTab }) => {
	const { title, description, key, icon } = item

	return (
		<>
			<div
				onClick={() => setTab(key)}
				className={`relative p-5 flex flex-col text-center items-center justify-start break-words bg-white rounded  shadow-lg h-50 w-40 mr-5 ${
					tab === key ? 'border border-orange-500' : 'border'
				}`}
			>
				{icon}
				<h6 className="text-orange-400 font-bold text-sm mt-2">{title}</h6>
				<p className="text-xs text-gray-500">{description}</p>
			</div>
		</>
	)
}

Card.defaultProps = {
	title: '',
	description: '',
	children: null,
	loading: false,
}

Card.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default Card
