import React, { useEffect, useState } from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import { BsFillEnvelopeOpenFill } from 'react-icons/bs'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import {
	batchSubscribeToEvent,
	fetchParticipant,
} from '../../services/subscriptions'
import { useAlert } from 'react-alert'
import GuestList from '../guest-list'
import Loader from '../loader'
const ParticipanttModal = ({ opened, text, title, eventId }) => {
	// const [isLoading, setIsLoading] = useState(true)
	const {
		events,
		event,
		isLoading,
		user,
		modal,
		participants,
		attendees,
		contribution,
	} = useHookstate(store)
	const eventParticipants = participants.get()
	const eventContribution = contribution.get()
	const eventAttendees = attendees.get()
	const alert = useAlert()
	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		setIsLoading(true)
	// 		await fetchParticipant(eventId, () => {
	// 			setIsLoading(false)
	// 		})
	// 	}
	// 	fetchData()
	// }, [eventId])
	const actionButtons = null

	const header = (
		<div className="flex flex-row justify-center items-center text-3xl bg-orange-400 text-white w-full">
			<div className="p-6">
				<BsFillEnvelopeOpenFill />
			</div>
			<div className="bg-orange-400 font-bold text-white text-3xl">{title}</div>
		</div>
	)
	return (
		<Modal
			header={header}
			actionButtons={actionButtons}
			opened={opened}
			icon={<GrClose onClick={() => modal.open.set(false)} />}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
				<div className="text-gray-400">{text}</div>
				{isLoading.get() ? (
					<Loader />
				) : (
					<GuestList
						participants={eventParticipants}
						attendees={eventAttendees}
					/>
				)}
			</div>
		</Modal>
	)
}

ParticipanttModal.propTypes = {}

ParticipanttModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
	text: '',
	actionStatus: false,
}

export default ParticipanttModal
