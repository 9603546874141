import React, { useEffect } from 'react'
import { BiCheckSquare } from 'react-icons/bi'
import { FaRedo } from 'react-icons/fa'
import { ErrorHandler } from '../../utils/service-utils'
import { DateTime } from 'luxon'
import SpentAmountScreen from '../SpentAmountScreen'
import ParticipanttModal from '../modal/participant-modal'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import { modalID } from '../../constants'
import { fetchParticipant } from '../../services/subscriptions'

const TableBody = ({ data, isHost, headerStyle }) => {
	const {
		modal,

		participants,
		isLoading,
		contribution,
	} = useHookstate(store)
	const [selectedEvent, setSelectedEvent] = React.useState({})
	useEffect(() => {}, [])
	const modalData = modal.data.get()

	// Function to capitalize the first letter of each word
	const capitalizeFirstLetterEachWord = (str) => {
		return str.replace(/\b\w/g, (char) => char.toUpperCase())
	}
	const fetchData = async (eventId) => {
		isLoading.set(true)
		await fetchParticipant(eventId, () => {
			isLoading.set(false)
		})
	}

	const showParticipant = async () => {
		participants.set([])
		await fetchData(selectedEvent)
		modal.set({
			open: true,
			data: {
				id: modalID.SHOW_PARTICIPANT,
				title: 'Participant',
				message: `Show `,
				eventId: selectedEvent,
			},
		})
	}

	return (
		<>
			<tbody>
				{data && data.length
					? data.map((row, index) => {
							const {
								eventType,
								eventName,
								date,
								attendees = 0,
								amountEarned = 0,
								amountSpent = 0,
								createdAt,
								meetingUrl,
								street = '',
								city = '',
								state = '',
								zipcode = '',
								country = '',
								eventFrequency,
								id,
								status,
							} = row

							const timestampMilliseconds =
								createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000

							const luxonDateTime = DateTime.fromMillis(timestampMilliseconds)
							const createdAtDate = luxonDateTime.toFormat('dd / MM / yyyy')

							const address = `${street} ${city} ${state} ${country} ${zipcode}`

							const style1 = 'border-0 p-4 pb-5  bg-gray-200 text-left'
							const style2 = 'border-b p-4 pb-5 text-left'
							const style =
								selectedEvent === id
									? `${style1} text-left`
									: `${style2} text-left`

							return (
								// <React.Fragment key={id}>
								// 	<tr
								// 		className={`cursor-pointer hover-bg-gray-200 ${style} ${
								// 			index % 2 === 0 ? 'bg-gray-100' : ''
								// 		}`}
								// 		onClick={() => setSelectedEvent(id)}
								// 	>
								// 		<td>
								// 			{eventFrequency === 'recurring' ? (
								// 				<FaRedo
								// 					size={25}
								// 					className="self-center ml-10 font-medium text-gray-500"
								// 				/>
								// 			) : (
								// 				<BiCheckSquare
								// 					size={40}
								// 					className="self-center ml-10 font-medium text-gray-500"
								// 				/>
								// 			)}
								// 		</td>
								// 		<td
								// 			className={`text-gray-500 font-medium text-base ${style}`}
								// 		>
								// 			{capitalizeFirstLetterEachWord(eventName)}
								// 		</td>
								// 		<td
								// 			className={`text-gray-500 font-medium text-sm ${style}`}
								// 		>
								// 			{date}
								// 		</td>
								// 		<td
								// 			className={`text-gray-500 font-medium text-sm ${style}`}
								// 		>
								// 			{attendees}
								// 		</td>
								// 		<td
								// 			className={`text-gray-500 font-medium text-sm ${style}`}
								// 		>
								// 			{isHost ? (
								// 				<>
								// 					<span>$ {amountEarned} </span>
								// 					<span className="text-green-500 ml-2">EARNED</span>
								// 				</>
								// 			) : (
								// 				<SpentAmountScreen
								// 					eventId={id}
								// 					total_donation={amountSpent}
								// 					status={status}
								// 				/>
								// 			)}
								// 		</td>
								// 	</tr>

								// 	{selectedEvent === id ? (
								// 		<tr className={style}>
								// 			<td>
								// 				<p className="text-sm text-gray-500 w-10 ml-10">
								// 					{eventFrequency === 'recurring'
								// 						? 'Recurring Event'
								// 						: ''}
								// 				</p>
								// 			</td>
								// 			<td>{/* Add content here if needed */}</td>
								// 			<td>
								// 				<p className="text-sm text-left text-gray-500 w-30 ml-10">
								// 					Event Created:
								// 				</p>
								// 				<p className="text-sm text-gray-500 w-30 ml-5">
								// 					{createdAtDate}
								// 				</p>
								// 			</td>
								// 			<td>
								// 				<p
								// 					onClick={() => showParticipant()}
								// 					className="text-sm text-gray-500 w-25 ml-10 cursor-pointer"
								// 				>
								// 					See Attendee List
								// 				</p>
								// 			</td>
								// 			<td>
								// 				<button className="p-2 bg-green-600 text-white text-sm my-5 mr-10 rounded">
								// 					Download Statement
								// 				</button>
								// 			</td>
								// 		</tr>
								// 	) : null}
								// </React.Fragment>
								<React.Fragment key={id}>
									<tr
										className={`cursor-pointer hover:bg-gray-200 ${style} ${
											index % 2 === 0 ? 'bg-gray-100' : ''
										}`}
										onClick={() => setSelectedEvent(id)}
									>
										<td className="p-4 text-left">
											{eventFrequency === 'recurring' ? (
												<FaRedo
													size={25}
													className="self-center font-medium text-gray-500"
												/>
											) : (
												<BiCheckSquare
													size={40}
													className="self-center font-medium text-gray-500"
												/>
											)}
										</td>
										<td className={`text-base ${style} text-left`}>
											{capitalizeFirstLetterEachWord(eventName)}
										</td>
										<td className={`text-sm ${style} text-left`}>{date}</td>
										<td className={`text-sm ${style} text-left`}>
											{attendees}
										</td>

										<td className={`text-sm ${style} text-left`}>
											{isHost ? (
												<>
													<span>$ {amountEarned} </span>
													<span className="text-green-500 ml-2">EARNED</span>
												</>
											) : (
												<SpentAmountScreen
													eventId={id}
													total_donation={amountSpent}
													status={status}
												/>
											)}
										</td>
									</tr>

									{selectedEvent === id ? (
										<tr className={style}>
											<td className="p-4 text-left">
												<p className="text-sm text-gray-500">
													{eventFrequency === 'recurring'
														? 'Recurring Event'
														: ''}
												</p>
											</td>
											<td className="p-4 text-left">
												{/* Add content here if needed */}
											</td>
											<td className="p-4 text-left">
												<p className="text-sm text-gray-500">Event Created:</p>
												<p className="text-sm text-gray-500">{createdAtDate}</p>
											</td>
											<td className="p-4 text-left">
												<p
													onClick={() => showParticipant()}
													className="text-sm text-gray-500 cursor-pointer"
												>
													See Attendee List
												</p>
											</td>
											<td className="p-4 text-left">
												<button className="p-2 bg-green-600 text-white text-sm rounded">
													Download Statement
												</button>
											</td>
										</tr>
									) : null}
								</React.Fragment>
							)
					  })
					: null}
			</tbody>
		</>
	)
}

export default TableBody
