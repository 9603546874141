import React, { useState } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import Guest from '../guest'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Input from '../forms/input/formik-input'
import { updateSubscriptionInRealTimeDatabase } from '../../services/subscriptions'
import { updateEvent } from '../../services/events'
import { eventStatus } from '../../constants'

export default function GuestList({ participants = [], attendees = [] }) {
	const {
		user,
		onGoingEvents,
		isLoading,
		eventDetail,
		enableShare,
		sharedAll,
	} = useHookstate(store)
	const eventObject = eventDetail.get()
	const { eventId } = useParams()
	const event = onGoingEvents[eventId].get()
	const share = enableShare.get()
	const shared = sharedAll.get()
	const [showMenu, setShowMenu] = useState(null)

	const shareAll = (share) => {
		participants.forEach((participant) => {
			updateSubscriptionInRealTimeDatabase(eventId, participant?.email, {
				share,
			})
		})
		updateEvent(
			{
				id: event.id,
				eventName: event.eventName,
				sharedAll: share,
				uid: event.uid,
			},
			() => {
				sharedAll.set(!shared)
				isLoading.set(false)
				enableShare.set(false)
			},
			() => {
				isLoading.set(false)
			}
		)
	}

	return (
		<div className="flex flex-col flex-1 overflow-y-auto max-h-96">
			{' '}
			{/* Adjust max-h-96 as needed */}
			<div className="divide-y divide-slate-200">
				<div className="flex justify-between items-center h-14 px-5 my-2 border-b-1 border-gray-200 ">
					<div className="text-left text-gray-500 text-1xl">
						Participating Guests{' '}
						<span className="ml-10 text-green-500">
							{participants ? participants.length : '0'}
						</span>{' '}
					</div>
					{event?.isHost && (
						<div className="text-left text-gray-500 text-1xl ml-10 mr-10">
							Number of attended Guests{' '}
							<span className="ml-10 text-green-500">
								{attendees ? attendees.length : '0'}
							</span>{' '}
						</div>
					)}

					{event?.isHost && (
						<div className="text-left text-gray-500 text-sm">
							<button
								className="bg-green-500 font-normal text-white py-2 px-5 rounded-none"
								onClick={() => enableShare.set(!share)}
							>
								Share
							</button>
						</div>
					)}
				</div>
				{share && event?.isHost ? (
					<div className="flex items-center h-12 px-5 ">
						<div className="mr-5 flex justify-even items-center">
							<div className="text-left text-gray-500 text-1xl">
								<div className="text-base my-10 flex">
									<p className="text-sm mr-5">share all</p>
									<Input
										name="isAgreement"
										type="checkbox"
										checked={shared}
										handleChange={() => shareAll(!shared)}
									/>
								</div>
							</div>
						</div>
					</div>
				) : null}
				{participants && participants.length
					? participants.map((participant) => {
							return (
								<Guest
									participant={participant}
									enableShare={share}
									isHost={event?.isHost}
									event={event}
									showMenu={showMenu}
									setShowMenu={setShowMenu}
								/>
							)
					  })
					: null}
			</div>
		</div>
	)
}

GuestList.defaultProps = {
	participants: [],
}

GuestList.prototype = {
	participants: PropTypes.array,
}
