import { getFunctions, httpsCallable } from 'firebase/functions'
import store from '../store/store'

import { firestoreRef } from '../routes'
import { doc, updateDoc } from 'firebase/firestore'

export const exchangeTokens = async (data) => {
	try {
		store.PaymentMethod.loadingPayment.set(true)
		const result = await httpsCallable(
			getFunctions(),
			'payments-exchangeTokens'
		)(data)
		store.PaymentMethod.loadingPayment.set(false)
	} catch (e) {
		console.error(e)
		store.PaymentMethod.loadingPayment.set(false)
	}
}

export const retrievePaymentMethods = async (fundType, onSuccess, onError) => {
	try {
		store.PaymentMethod.loadingPayment.set(true)
		const result = await httpsCallable(
			getFunctions(),
			'payments-retrievePaymentMethods'
		)({})
		console.log('___PAyments:__', result)
		store.PaymentMethod.set(result.data)
		store.PaymentMethod.loadingPayment.set(false)
		return result.data
	} catch (e) {
		console.error(`Error retrieving payment methods: ${e}`)
		store.PaymentMethod.loadingPayment.set(false)
	}
	return {}
}

export const updatePaymentMethod = async ({ data, onSuccess, onError }) => {
	try {
		const payload = {
			...data,
			updatedAt: Date.now(),
			updatedBy: store.user.uid.get(),
		}

		const paymentMethodRef = doc(
			firestoreRef,
			'users-payment-methods',
			payload.id
		)
		// Update the document with the provided data
		const response = await updateDoc(paymentMethodRef, payload)

		if (onSuccess) {
			onSuccess('Payment method updated successfully.')
		}
	} catch (e) {
		console.error(`Error updating payment method: ${e}`)
		if (onError) {
			onError('Error updating payment method.')
		}
	}
}

export const getPlaidLinkToken = async ({ onSuccess, onError }) => {
	try {
		const result = await httpsCallable(
			getFunctions(),
			'payments-retrievePlaidLinkToken'
		)()

		onSuccess('Link token retrieved')
		return result.data.toString()
	} catch (e) {
		console.error(e)
		onError(e.toString())
	}
	return ''
}
