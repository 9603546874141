import {
	query,
	orderBy,
	startAfter,
	limit,
	collection,
	getDocs,
	QueryDocumentSnapshot,
	where,
} from 'firebase/firestore'
import { eventStatus } from '../constants'
// import { logger } from './log-util'

export const isOwner = (event, user) => {
	return event.uid === user.uid
}

export const checkStatus = (s) => {
	if (s !== eventStatus.STARTED && s !== eventStatus.LEFT_EVENT) {
		return eventStatus.INLOBBY
	}
	return eventStatus.JOINED
}

// export const getStatuses = (statuses = ['active'], status) => {
// 	let statusez = statuses
// 	if (status === 'both') {
// 		statusez = ['active', 'inactive', 'ended']
// 	} else if (status === 'inactive') {
// 		statusez = ['inactive']
// 	}

// 	return statusez
// }

// Define the getStatuses function as needed
export const getStatuses = (status) => {
	// Implement your logic to determine statuses based on the provided status value.
	// This is just a placeholder, modify it to fit your use case.
	if (status === 'active') {
		return [
			'active',
			'started',
			'joined',

			'left_event',
			'inLobby',
			'request_invite',
			'request_accepted',
		]
	} else if (status === 'inactive') {
		return ['inactive']
	} else {
		return ['active', 'inactive', 'started']
	}
}

export const getEventObject = async (
	query,
	nowAndFuture,
	isSubscription = false
) => {
	const allEventsCollections = await getDocs(query)

	const events = []
	const docs = allEventsCollections.docs

	for (const d of docs) {
		let eventObject = d.data()
		let event = eventObject

		if (isSubscription) {
			event = eventObject.event
			if (!event) {
				continue
			}
		}

		if (event.status === 'ended' && event?.eventFrequency !== 'recurring') {
			// logger.debug(event.id, 'skipping1')
			continue
		}

		if (!isSubscription) {
			event['isHost'] = true // this function retrieves all events created by uid.

			events.push(event)
		} else {
			eventObject.event = event
			events.push(eventObject)
		}
	}

	return events
}

/**
 * Retrieve paginated data based on the provided query, pagination options, and orderBy field.
 * @param {Query} query - The Firestore query to retrieve data from.
 * @param {Object} paginate - Pagination options including `next`, `limit`, and `orderBy`.
 * @returns {Object} - An object containing the paginated data and next pagination token.
 */
export const getPaginatedData = async (paginatedQuery, paginate) => {
	const { next, limit: pageLimit, orderByField } = paginate

	// Create a paginated query with startAfter and limit

	if (next) {
		paginatedQuery = query(paginatedQuery, startAfter(next))
	}
	paginatedQuery = query(paginatedQuery, limit(pageLimit))

	// Execute the paginated query
	const querySnapshot = await getDocs(paginatedQuery)

	// Extract data from the query results
	const data = querySnapshot.docs.map((doc) => doc.data())

	// Determine the next pagination token
	const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
	const nextToken = lastDoc ? lastDoc.data()[orderByField] : null

	return { data, next: nextToken }
}

// Function to apply filters and pagination to a Firestore query
export const applyFiltersAndPagination = async (
	collectionRef,
	filters,
	orderByClause,
	limit,
	startAfter,
	searchTerm
) => {
	try {
		let queryRef = query(collectionRef, ...filters)

		// Apply search term filter if searchTerm is provided
		if (searchTerm) {
			queryRef = query(
				queryRef,
				where('eventName', '>=', searchTerm.toLowerCase()),
				where('eventName', '<=', searchTerm.toLowerCase() + '\uf8ff')
			)
		}

		queryRef = query(queryRef, orderByClause)

		if (startAfter) {
			queryRef = query(queryRef, startAfter(startAfter))
		}

		queryRef = query(queryRef, limit)

		const querySnapshot = await getDocs(queryRef)
		const data = []

		querySnapshot.forEach((doc) => {
			const eventData = doc.data()
			data.push(eventData)
		})

		const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
		const nextToken = lastDoc ? lastDoc.data()[orderByClause.field] : null

		return { data, next: nextToken }
	} catch (error) {
		throw error
	}
}

export const deSanitizedEmail = (email) => {
	if (!email) {
		return email
	}
	let sanitizedEmail
	if (email) {
		sanitizedEmail = email
			.replace(/\*dot\*/g, '.')
			.replace(/\*hash\*/g, '#')
			.replace(/\*dollar\*/g, '$')
			.replace(/\*forwardslash\*/g, '/')
			.replace(/\*opensquare\*/g, '[')
			.replace(/\*closesquare\*/g, ']')
	}

	return sanitizedEmail
}
