import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'

import {
	retrieveEvents,
	retrievePaginatedEventsList,
} from '../../services/events'
import DataTable from '../../components/DataTable'
import SearchBar from '../../components/layout/search'
import SearchBarWithButton from '../../components/searchBarWithButton'
import DatePicker from '../../components/forms/date-picker/date-picker'
import EditModeDatePicker from '../../components/forms/date-picker/editmode-date-picker'
import { FiChevronLeft, FiLoader, FiChevronRight } from 'react-icons/fi'
import TabButton from '../../components/forms/button/tab-button'
import MyEvents from '../../components/MyEvents'
import AttendedEvents from '../../components/AttendedEvents'
import { retrievePaginatedSubscriptions } from '../../services/subscriptions'
import { TabHeader } from '../../components/card-tab/tab-header'
import { TabBody } from '../../components/card-tab/tab-body'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { FaMoneyCheck } from 'react-icons/fa'

export default function Events() {
	const { user, event, events, isEventDeleted } = useHookstate(store)
	const emailVerified = user.emailVerified.get()
	const { selectedEvent } = event.get()
	const eventList = events.get({ noproxy: true })
	const [filter, setFilter] = React.useState({})
	const [tab, setTab] = React.useState()
	console.log(events, '+++')
	useEffect(() => {
		retrievePaginatedEventsList({
			paginate: { limit: 10, orderBy: 'unixTimestamp', orderDirection: 'desc' },
			uid: user.uid.get(),
		})

		retrievePaginatedSubscriptions({
			email: user.email.get(),
			paginate: { limit: 10, orderBy: 'unixTimestamp', orderDirection: 'desc' },
		})
	}, [tab])

	return (
		<div className="container flex-col items-center justify-center relative">
			{!emailVerified && (
				<div className="w-full text-center">Please Verify Your email</div>
			)}

			<div className="container p-4 flex  flex-col items-start justify-center">
				<TabHeader
					items={[
						{
							title: 'My Events',
							description: 'View list of my hosted events',
							icon: (
								<img
									src={require('../../assets/img/host-event-icon.png')}
									width={50}
									height={50}
								/>
							),
							key: 'my-event',
						},
						{
							title: 'Attended Events',
							description: 'View list of my attended events',
							icon: (
								<img
									src={require('../../assets/img/Attended-event.png')}
									width={100}
									height={100}
								/>
							),
							key: 'attended-event',
						},
					]}
					setTab={setTab}
					tab={tab}
				/>
				<TabBody
					items={{
						'my-event': <MyEvents />,
						'attended-event': <AttendedEvents />,
					}}
					tab={tab}
				/>
			</div>
		</div>
	)
}
