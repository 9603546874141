/*eslint-disable*/
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import { AuthContext } from '../auth/auth-provider'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import {
	changeTimeZone,
	convertTime12to24,
	dateWithTimeZone,
	getTime,
} from '../../utils/date-utils'
import SidebarButton from '../forms/button/sidebar-button'

import moment from 'moment'
import { updateEvent } from '../../services/events'

import { useNavigate } from 'react-router-dom'
import { eventAccess, eventStatus, modalID } from '../../constants'
import {
	batchSubscribeToEvent,
	updateSubscription,
} from '../../services/subscriptions'
import { checkStatus } from '../../utils/event-utils'
import { FiCheck, FiLoader, FiX } from 'react-icons/fi'

export default function EventsOngoingSidebar() {
	const [collapseShow, setCollapseShow] = React.useState('hidden')
	const { signout } = React.useContext(AuthContext)

	const { user, onGoingEvents, modal, alert, isLoading, eventDetail } =
		useHookstate(store)
	const firstName = user.displayName.get()
	const email = user.email.get()
	const emailVerified = user.emailVerified.get()
	const loading = isLoading.get()
	const eventObject = eventDetail.get({ noproxy: true })
	const uid = user.uid.get()

	const navigate = useNavigate()

	const { eventId } = useParams()

	const event = onGoingEvents[eventId].get()
	const status = event?.status

	const emailErrorMessage = useHookstate('')
	const submittingEmails = useHookstate(false)
	const inviteRequestSent = useHookstate(false)

	const time = getTime(event?.startTime)
	const date = new Date(event?.date)
	const newDate = dateWithTimeZone(
		event?.startTimeZone,
		date.getFullYear(),
		date.getMonth() + 1,
		date.getDate(),
		time.hour,
		time.minute
	)
	const isToday = moment
		.tz()
		.isSame(
			moment.tz(
				`${event?.date}T${time.hour}:${time.minute}:00`,
				`${event?.startTimeZone}`
			),
			'day'
		)
	const isPast = moment
		.tz()
		.isAfter(
			moment.tz(
				`${event?.date}T${time.hour}:${time.minute}:00`,
				`${event?.startTimeZone}`
			),
			'day'
		)

	const requestToJoin = (email) => {
		submittingEmails.set(true)
		if (email) {
			batchSubscribeToEvent(
				{
					eventId,
					status: eventStatus.REQUEST_INVITE,
					event: event,
					emailList: [email],
				},
				() => {
					modal.open.set(false)
					submittingEmails.set(false)
					inviteRequestSent.set(true)
					alert.set({
						show: true,
						data: { message: 'Request Successfully Sent', type: 'success' },
					})
				},
				(e) => {
					alert.set({
						show: true,
						data: { message: 'Unable to send request', type: 'error' },
					})
				}
			)
		}
	}
	const onSuccess = (res) => {
		onGoingEvents[eventId].status.set('started')
		isLoading.set(false)
		alert.set({
			show: true,
			data: { message: 'Event has been started', type: 'success' },
		})
	}

	const onFailure = (res) => {
		isLoading.set(false)
		alert.set({
			show: true,
			data: { message: 'Event failed to be started', type: 'error' },
		})
	}

	const onSuccessfulJoined = (res) => {
		isLoading.set(false)
		alert.set({
			show: true,
			data: { message: 'You have joined the event', type: 'success' },
		})
	}

	const onJoinedFailure = (err) => {
		isLoading.set(false)
		alert.set({
			show: true,
			data: { message: 'Event failed to be started', type: 'error' },
		})
	}

	const startEvent = () => {
		if (isPast) {
			alert.set({
				show: true,
				data: { message: 'Event has passed!', type: 'success' },
			})
			return
		}
		if (!isToday) {
			alert.set({
				show: true,
				data: {
					message: 'Event cannot start until the day of the event!',
					type: 'success',
				},
			})
			return
		}

		isLoading.set(true)
		updateEvent(
			{
				id: event.id,
				eventName: event.eventName,
				onGoingStatus: eventStatus.STARTED,
				hostStartedAt: new Date(),
				uid: event.uid,
			},
			onSuccess,
			onFailure
		)
	}

	const leaveEvent = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.LEAVE_EVENT,
				title: 'Leave Event',
				message: `The ${event.eventName} has not yet ended, do you really want to leave?`,
			},
		})
	}

	const changeDisplayname = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.CHANGE_DISPLAY_NAME,
				title: 'Change Display Name',
				message: `Enter name you want to display for this event`,
			},
		})
	}

	const donate = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.SINGLE_DONATION,
				title: 'Donate',
				message: `Donate to event`,
			},
		})
	}

	const joinEvent = () => {
		if (isPast) {
			alert.set({
				show: true,
				data: { message: 'Event has passed!', type: 'success' },
			})
			return
		}

		if (!isToday) {
			alert.set({
				show: true,
				data: {
					message: 'You can not join until the day of the event!',
					type: 'success',
				},
			})
			return
		}

		isLoading.set(true)

		updateSubscription(
			{
				eventId: event.id,
				email,
				status: checkStatus(eventObject.status),
				guestJoinedAt: new Date(),
			},
			onSuccessfulJoined,
			onJoinedFailure
		)
	}

	const endEvent = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.END_EVENT,
				title: 'End Event',
				message: `Do you really want to end ${event.eventName} event?`,
			},
		})
	}

	let startEventButtonHtml
	if (
		event?.isHost &&
		status === eventStatus.ACTIVE &&
		event?.onGoingStatus !== eventStatus.STARTED
	) {
		startEventButtonHtml = (
			<SidebarButton
				disabled={loading}
				label="Start Event"
				className="mt-10"
				onClick={() => {
					startEvent()
				}}
			/>
		)
	}
	console.log('__EVENT__', event?.onGoingStatus, status, event?.isHost)
	let endEventButtonHtml
	if (event?.isHost && event?.onGoingStatus === eventStatus.STARTED) {
		endEventButtonHtml = (
			<SidebarButton
				disabled={loading}
				label="End Event"
				className="mt-10 md:mt-auto"
				onClick={() => {
					endEvent()
				}}
			/>
		)
	}

	let leaveEventButtonHtml
	if (
		eventObject?.status === eventStatus.JOINED ||
		(eventObject?.status === eventStatus.INLOBBY && !event?.isHost)
	) {
		leaveEventButtonHtml = (
			<SidebarButton
				disabled={loading}
				label="Leave Event"
				className="mt-10 md:mt-auto"
				onClick={() => {
					leaveEvent()
				}}
			/>
		)
	}
	console.log('___eventObject', eventObject)
	let joinEventButtonHtml
	if (
		eventObject?.invited &&
		eventObject?.status !== eventStatus.JOINED &&
		eventObject?.status !== eventStatus.INLOBBY &&
		!event?.isHost
	) {
		joinEventButtonHtml = (
			<SidebarButton
				label="Join Event"
				className="mt-10"
				onClick={() => {
					joinEvent()
				}}
			/>
		)
	}

	let changeDisplayNameButtonHtml
	if (eventObject?.status === eventStatus.JOINED && !event?.isHost) {
		changeDisplayNameButtonHtml = (
			<SidebarButton
				label="Change Display Name"
				className="mt-10"
				onClick={() => {
					changeDisplayname()
				}}
			/>
		)
	}

	let singleDonationButtonHtml
	if (eventObject?.status === eventStatus.JOINED && !event?.isHost) {
		singleDonationButtonHtml = (
			<SidebarButton
				label="Donate"
				className="mt-10"
				onClick={() => {
					donate()
				}}
			/>
		)
	}

	let inviteGuestHtml
	if (event?.isHost) {
		inviteGuestHtml = (
			<SidebarButton
				label="Invite Guest"
				className="mt-10"
				onClick={() => {
					modal.set({ open: true, data: { id: 'invite_guest' } })
				}}
			/>
		)
	}

	let requestToJoinEventHtml
	if (
		!event?.isHost &&
		event?.eventAccess === eventAccess.PRIVATE &&
		!eventObject?.invited
	) {
		requestToJoinEventHtml = (
			<SidebarButton
				label={
					submittingEmails.get() ? (
						<FiLoader size={'25'} />
					) : inviteRequestSent.get() ||
					  (!eventObject?.invited && eventObject?.email) ? (
						<>
							<span>Requested </span>{' '}
							<FiCheck
								size={'16'}
								className="font-medium text-green-500 rounded ml-5"
							/>
						</>
					) : (
						'Request To Join'
					)
				}
				className="mt-10"
				disabled={
					submittingEmails.get() ||
					(!eventObject?.invited && eventObject?.email)
				}
				onClick={() => {
					requestToJoin(email)
				}}
			/>
		)
	}

	return (
		<nav className="md:left-0 md:block md:fixed bg-secondary-400">
			{/* {emailVerified && ( */}
			<>
				{user.get() && !user.anon.get() && (
					<div className="border-8 border-secondary-400 mx-0 md:top-5 md:bottom-0 z-10 py-4 px-6 shadow-xl flex flex-wrap items-center justify-between z-10 md:w-72">
						<div className="grid grid-row-2 gap-2">
							<img
								alt="..."
								className="w-28 h-28 object-cover rounded-full align-middle border-none"
								src={
									user.photoURL.get() ||
									require('../../assets/img/team-1-800x800.jpg')
								}
							/>
							<div className="flex flex-col content-center justify-center text-white">
								<div className="font-bold">Hi {firstName}</div>
								<div className="text-sm">{email}</div>
							</div>
						</div>
					</div>
				)}
				<div className="bg-secondary-400 px-6 md:overflow-y-auto md:h-screen md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4">
					<div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
						{/* Toggler */}
						<button
							className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-black"
							type="button"
							onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
						>
							<FaBars />
						</button>

						{/* Collapse */}
						<div
							className={
								'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
								collapseShow
							}
						>
							{/* Collapse header */}
							<div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
								<div className="flex flex-wrap">
									<div className="w-6/12">
										<Link
											className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
											to="/"
										>
											LOGO HERE
										</Link>
									</div>
									<div className="w-6/12 flex justify-end">
										<button
											type="button"
											className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
											onClick={() => setCollapseShow('hidden')}
										>
											<FaTimes />
										</button>
									</div>
								</div>
							</div>

							{/* Heading */}
							<div className="flex flex-col flex-1 md:pb-60">
								<SidebarButton
									label="Home"
									onClick={() => {
										navigate(-1)
									}}
								/>
								{event && (
									<>
										{inviteGuestHtml}
										{requestToJoinEventHtml}

										{startEventButtonHtml}
										{endEventButtonHtml}
										{joinEventButtonHtml}
										{changeDisplayNameButtonHtml}
										{singleDonationButtonHtml}
										{leaveEventButtonHtml}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
			{/* )} */}
		</nav>
	)
}
