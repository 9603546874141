import { useHookstate } from '@hookstate/core'
import React, { useEffect, useRef, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import store from '../../store/store'
import Card from '../cards/card'
import { batchSubscribeToEvent } from '../../services/subscriptions'
import EventDetail from './event-detail'
import { useAlert } from 'react-alert'
import { retrieveEmails, retrieveEvent } from '../../services/events'
import QrcodeImageSlider from '../qrcode-slider/qrcode-slider'
import EmailTagInput from '../forms/dynamic-input/email-tag-input'
import { validateEmail } from '../../utils/form-utils'
import { eventTypeConstant } from '../../constants'
import { debounce } from 'lodash'

export default function EventDetails() {
	const { event, events, user, isEventDeleted } = useHookstate(store)
	const alert = useAlert()
	const { selectedEvent, editMode, guestEditMode } = event.get()
	const submittingEmails = useHookstate(false)
	const submittedEmails = useHookstate(false)
	const errors = useHookstate({})
	const eventObject = events[selectedEvent]?.get({ noproxy: true })
	let emailErrorMessage = useHookstate('')
	const [emailInput, setEmailInput] = useState('')
	const [suggestedEmails, setSuggestedEmails] = useState([])

	useEffect(() => {
		retrieveSubscriptionEventDetails()
	}, [eventObject?.displayName])

	useEffect(() => {
		event.selectedEvent.set(selectedEvent)
		retrieveSubscriptionEventDetails()
	}, [selectedEvent])

	const retrieveSubscriptionEventDetails = () => {
		if (eventObject?.displayName && !eventObject.id) {
			retrieveEvent(eventObject.eventId, (data) => {
				events[selectedEvent].merge(data)
			})
		}
	}

	const handleInputChange = (value) => {
		setEmailInput(value)
	}

	const fetchEmailSuggestions = async (query) => {
		try {
			await retrieveEmails(
				query,
				(res) => {
					setSuggestedEmails(res)
				},
				() => {}
			)
		} catch (error) {
			console.error('Error fetching email suggestions:', error)
		}
	}

	useEffect(() => {
		// Debounce the function to delay fetching suggestions on input change
		const fetchSuggestionsDebounced = debounce(fetchEmailSuggestions, 500)
		if (emailInput) {
			fetchSuggestionsDebounced(emailInput)
		}

		// Cleanup function to cancel the debounce timer
		return () => fetchSuggestionsDebounced.cancel()
	}, [emailInput])

	const bulkSubmit = (emailList, reset) => {
		submittingEmails.set(true)

		if (emailList.includes(user.email.get())) {
			errors['email'].set(true)
			emailErrorMessage.set('You cant invite yourself to your own event.')
		} else if (emailList.length > 0) {
			batchSubscribeToEvent(
				{
					eventId: eventObject.id,
					event: eventObject,
					emailList: [...new Set(emailList)],
				},
				() => {
					submittingEmails.set(false)
					submittedEmails.set(true)
					if (reset) {
						reset()
					}
					alert.show('Invitation Successfully Sent', { type: 'success' })
				},
				(e) => {
					console.log(e)
					alert.show('Unable to send invitation', { type: 'error' })
				}
			)
		}
		submittingEmails.set(false)
	}

	const dynamicInput = (
		<>
			<EmailTagInput
				placeholder="Enter email and press enter"
				error={emailErrorMessage.get()}
				clearError={() => emailErrorMessage.set('')}
				isSubmitting={submittingEmails.get()}
				onSubmit={bulkSubmit}
				inputValue={emailInput}
				onInputChange={handleInputChange}
				suggestedEmails={suggestedEmails}
				clearSuggestion={setSuggestedEmails}
				validate={validateEmail}
			/>
		</>
	)

	if (events.length === 0) {
		return <></>
	}
	return (
		<div className="min-w-0 text-gray-500 z-40">
			<Card>
				{/* isEventDelete */}

				<div className="text-left text-orange-400 capitalize font-bold text-lg w-4/5">
					{editMode
						? 'Edit Event'
						: `${eventObject?.displayName || eventObject?.eventName} ${
								eventObject?.eventType === eventTypeConstant.OTHER
									? eventObject?.otherEventType
									: eventObject?.eventType
						  }`}
				</div>
				<div
					className={`${
						editMode ? 'hidden' : ''
					} grid grid-cols-2 gap-2 absolute top-5 right-5 text-black`}
				>
					<div
						className="cursor-pointer hover:bg-gray-200"
						onClick={() => {
							console.log('see me!3', eventObject?.isHost)
							eventObject?.isHost === false
								? event.guestEditMode.set(true)
								: event.editMode.set(true)

							console.log('see me!', event.get().eventMode)
						}}
					>
						<FiEdit size={28} />
					</div>
					{eventObject?.uid === user.uid.get() && (
						<div
							className={`${
								eventObject?.isHost === false ? 'hidden' : ''
							} cursor-pointer hover:bg-gray-200`}
							onClick={() => {
								isEventDeleted.set(true)
							}}
						>
							<MdDelete size={28} />
						</div>
					)}
				</div>
				<div className="grid grid-cols-1 gap-2 ">
					<EventDetail
						guestEditMode={guestEditMode}
						editMode={editMode}
						event={eventObject}
						onCancel={() => {
							event.editMode.set(false)
							event.guestEditMode.set(false)
						}}
						onUpdate={() => {
							event.editMode.set(false)
							event.guestEditMode.set(false)
						}}
						user={user}
					>
						<QrcodeImageSlider />
					</EventDetail>
					{eventObject?.uid === user.uid.get() &&
					(eventObject.eventAccess === 'public' ||
						eventObject.status !== 'inactive') ? (
						<div className={`${editMode ? 'hidden' : ''}`}>
							<div className="font-bold text-orange-400">Invite Guest</div>
							{dynamicInput}
						</div>
					) : null}
				</div>
			</Card>
		</div>
	)
}
