import React, { useState, useEffect } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { useFormik } from 'formik'
import { useHookstate } from '@hookstate/core'
import { passwordSchema } from '../../utils/form-utils'
import Input from '../../components/forms/input/formik-input'
import Card from '../../components/cards/card'
import { uploadFile } from '../../utils/storage-util'
import store from '../../store/store'
import { FaUserLock } from 'react-icons/fa'
import {
	getCurrentUser,
	getUsersInfo,
	reAuthenticateUserCredentials,
	resetPassword,
	saveUserInfo,
	updateDisplayName,
	updateUserPhoto,
} from '../../services/users'
import PasswordInput from '../../components/forms/input/PasswordInput'
import { TabHeader } from '../../components/card-tab/tab-header'

import { saveNotification } from '../../services/notification'
import { AuthContext } from '../../components/auth/auth-provider'

const ChangePasswordScreen = () => {
	const { user, alert } = useHookstate(store)
	const { signout } = React.useContext(AuthContext)
	const submitting = useHookstate(false)

	const formik = useFormik({
		initialValues: {
			email: user.email.get(),
			password: '',
			new_password: '',
			confirm_password: '',
			uid: user.uid.get(),
		},
		validationSchema: passwordSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (data, { resetForm }) => {
			submitting.set(true)

			try {
				reAuthenticateUserCredentials(
					data.password,
					() => {
						resetPassword(
							data.new_password,
							async () => {
								alert.set({
									show: true,
									data: {
										message: `Password has been updated`,
										type: 'success',
									},
								})
								await saveNotification({
									sender: user.displayName.get(),
									receiver: user.uid.get(),
									status: 'unread',
									type: 'password-change',
									message: `You have successfully change your password`,
									title: 'Password Change',
									data: { eventId: user.uid.get() },
									email: user.email.get(),
								})
								signout()
								window.location.href = '/login'
								submitting.set(false)
							},
							(e) => {
								alert.set({
									show: true,
									data: {
										message: `Unable to send reset password email`,
										type: 'error',
									},
								})
								submitting.set(false)
							}
						)
					},
					(e) => {
						alert.set({
							show: true,
							data: {
								message: `User is not authenticated`,
								type: 'error',
							},
						})
						submitting.set(false)
					}
				)
			} catch (e) {
				console.error(e)
				alert.set({
					show: true,
					data: {
						message: `Enter current password`,
						type: 'error',
					},
				})
				submitting.set(false)
			}
		},
	})

	return (
		<div className="container p-4 flex  flex-col items-start justify-center ">
			<form
				className="flex flex-col items-start justify-center w-full "
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="w-8/12  text-sm text-blueGray-400 mt-4 grid grid-rows-1 gap-2 md:gap-1">
					<div className="w-full">
						<PasswordInput
							id="password"
							name="password"
							placeholder="Current Password"
							type="password"
							value={formik.values.password}
							handleChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik.errors.password}
						/>
					</div>

					<div className="w-full">
						<PasswordInput
							id="new_password"
							name="new_password"
							placeholder="New Password"
							type="password"
							value={formik.values.new_password}
							handleChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik.errors.new_password}
						/>
					</div>

					<div className="w-full">
						<PasswordInput
							id="confirm_password"
							name="confirm_password"
							placeholder="Confirm Password"
							type="password"
							value={formik.values.confirm_password}
							handleChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik.errors.confirm_password}
						/>
					</div>

					<div className="flex items-center justify-center">
						<button
							type="submit"
							className={`${
								submitting.get() ? 'bg-orange-200' : 'bg-orange-500'
							} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline rounded`}
						>
							{submitting.get() ? 'Sending...' : 'Get verification email'}
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default ChangePasswordScreen
