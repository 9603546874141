import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import Loader from '../loader'
import ParticipanttModal from '../modal/participant-modal'
import TableBody from './TableBody'
import TableHeader from './TableHeader'

const DataTable = ({ data, isHost, loading }) => {
	const {
		modal,

		participants,
		contribution,
	} = useHookstate(store)
	const modalData = modal.data.get()
	if (loading) {
		return (
			<div className="flex flex-col items-center justify-center min-h-[400px]">
				<Loader style={''} />
			</div>
		)
	}

	return (
		<>
			{modalData?.id === 'show_guests' && (
				<ParticipanttModal
					eventId={modalData.eventId}
					opened={modal?.open.get()}
					text={``}
					title="Guests"
					actionStatus={false}
					handleDelete={() => {}}
				/>
			)}

			<table class="border-collapse table-auto items-center justify-center w-full text-sm mb-auto">
				<TableHeader />

				<TableBody data={data} isHost={isHost} />
			</table>
		</>
	)
}

export default DataTable
