import { firestoreRef } from '../routes'
import { collection, query, getDocs, doc, setDoc } from 'firebase/firestore'
export const saveNotification = async ({
	sender,
	receiver,
	status,
	type,
	message,
	title,
	data,
	email,
}) => {
	console.log('Saving notification...')

	try {
		const eventsCollection = collection(firestoreRef, 'notifications')
		const docRef = doc(eventsCollection)

		const id = docRef.id

		const notification = {
			id,
			data,
			sender,
			receiver,
			status,
			type,
			title,
			message,
			createdAt: new Date(),
			email,
		}
		await setDoc(docRef, notification, { merge: true })
		console.log('Notification saved successfully.')

		return notification
	} catch (error) {
		console.error('Error saving notification:', error)
		throw new Error('Failed to save notification')
	}
}
