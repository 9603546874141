import { FaUserEdit, FaUserLock } from 'react-icons/fa'
import { TabHeader } from '../../components/card-tab/tab-header'
import { TabBody } from '../../components/card-tab/tab-body'
import ChangePasswordScreen from './change-password'
import { useState } from 'react'
import ChangeProfileScreen from './change-profile'

const Security = () => {
	const [tab, setTab] = useState()
	return (
		<div className="container p-4 flex  flex-col items-start justify-center">
			<TabHeader
				items={[
					{
						title: 'Edit Info',
						description: 'Edit profile information',
						icon: <FaUserEdit size={50} />,
						key: 'edit-info',
					},
					{
						title: 'Change Password',
						description: 'Change account password',
						icon: (
							<img
								src={require('../../assets/img/security.png')}
								width={50}
								height={50}
							/>
						),
						key: 'change-password',
					},
				]}
				setTab={setTab}
				tab={tab}
			/>
			<TabBody
				items={{
					'change-password': <ChangePasswordScreen />,
					'edit-info': <ChangeProfileScreen />,
				}}
				tab={tab}
			/>
		</div>
	)
}

export default Security
