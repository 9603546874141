export const getIpAddress = async () => {
	try {
		const response = await fetch('https://api64.ipify.org?format=json')
		const data = await response.json()

		return data.ip
	} catch (error) {
		console.error('Error getting IP address:', error)
		return null
	}
}
