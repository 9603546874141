import React from 'react'
import Card from './card'

export const TabHeader = ({ items = [], tab, setTab }) => {
	return (
		<div className="w-full flex flex-col justify-center">
			<div className="flex flex-row flex-start scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100 w-full mb-5">
				{items.length
					? items.map((item, index) => (
							<Card key={index} item={item} tab={tab} setTab={setTab} />
					  ))
					: null}
			</div>
			{tab && (
				<hr className="my-6 w-full border-t-1 border-gray-200 shadow-lg h-1  " />
			)}
		</div>
	)
}
