import React from 'react'
import PropTypes from 'prop-types'

const Modal = ({ icon, children, opened, onClose, actionButtons, header }) => {
	return (
		<>
			{opened ? (
				<>
					<div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
						<div className="relative min-w-[500px] md:min-w-[50%]  mx-auto my-6">
							<div className="bg-white rounded-lg shadow-lg overflow-hidden">
								<div className="flex justify-between items-center ">
									{header}
									<div className="absolute top-3 right-3 text-xl invisible md:visible">
										{icon}
									</div>
								</div>
								<div className="p-4">{children}</div>
								<div className="flex justify-center p-4">{actionButtons}</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

Modal.defaultProps = {
	title: '',
	children: null,
	opened: false,
	onClose() {},
	onSubmit() {},
	allowSubmit: true,
	cancelText: 'Close',
	submitText: 'Save Changes',
	showActionButtons: true,
}

Modal.propTypes = {
	title: PropTypes.string,
	children: PropTypes.object,
	opened: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	allowSubmit: PropTypes.bool,
	showActionButtons: PropTypes.bool,
}

export default Modal
