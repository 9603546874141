import React, { useEffect, useState } from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import { BsFillEnvelopeOpenFill } from 'react-icons/bs'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import { batchSubscribeToEvent } from '../../services/subscriptions'
import EmailTagInput from '../forms/dynamic-input/email-tag-input'
import { validateEmail } from '../../utils/form-utils'
import { useAlert } from 'react-alert'
import { retrieveEmails } from '../../services/events'
import debounce from 'lodash/debounce'

const InviteGuestModal = ({ opened, text, title, eventId }) => {
	const { events, event, user, modal } = useHookstate(store)
	const emailErrorMessage = useHookstate('')
	const submittingEmails = useHookstate(false)
	const [emailInput, setEmailInput] = useState('')
	const [suggestedEmails, setSuggestedEmails] = useState([])
	const { selectedEvent } = event.get()
	const eventObject = events[selectedEvent]?.get({ noproxy: true })
	const alert = useAlert()

	const actionButtons = null

	useEffect(() => {
		// Debounce the function to delay fetching suggestions on input change
		const fetchSuggestionsDebounced = debounce(fetchEmailSuggestions, 500)
		if (emailInput) {
			fetchSuggestionsDebounced(emailInput)
		}

		// Cleanup function to cancel the debounce timer
		return () => fetchSuggestionsDebounced.cancel()
	}, [emailInput])

	const bulkSubmit = (emailList, reset) => {
		submittingEmails.set(true)
		if (emailList.includes(user.email.get())) {
			submittingEmails.set(false)
			emailErrorMessage.set('You cant invite yourself to your own event.')
		} else if (emailList.length > 0) {
			batchSubscribeToEvent(
				{
					eventId,
					event: eventObject,
					emailList: [...new Set(emailList)],
				},
				() => {
					modal.open.set(false)
					submittingEmails.set(false)
					if (reset) {
						reset()
					}
					alert.show('Invitation Successfully Sent', { type: 'success' })
				},
				(e) => {
					console.log(e)
					alert.show('Unable to send invitation', { type: 'error' })
				}
			)
		}
	}

	const handleInputChange = (value) => {
		setEmailInput(value)
	}

	const fetchEmailSuggestions = async (query) => {
		try {
			await retrieveEmails(
				query,
				(res) => {
					setSuggestedEmails(res)
				},
				() => {}
			)
		} catch (error) {
			console.error('Error fetching email suggestions:', error)
		}
	}

	const dynamicInput = (
		<div className="m-4">
			<EmailTagInput
				placeholder="Enter email and press enter"
				error={emailErrorMessage.get()}
				clearError={() => emailErrorMessage.set('')}
				isSubmitting={submittingEmails.get()}
				inputValue={emailInput}
				onInputChange={handleInputChange}
				suggestedEmails={suggestedEmails}
				clearSuggestion={setSuggestedEmails}
				onSubmit={bulkSubmit}
				validate={validateEmail}
			/>
		</div>
	)

	const header = (
		<div className="flex flex-row justify-center items-center text-3xl bg-orange-400 text-white w-full">
			<div className="p-6">
				<BsFillEnvelopeOpenFill />
			</div>
			<div className="bg-orange-400 font-bold text-white text-3xl">{title}</div>
		</div>
	)

	return (
		<Modal
			header={header}
			actionButtons={actionButtons}
			opened={opened}
			icon={<GrClose onClick={() => modal.open.set(false)} />}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
				<div className="text-gray-400">{text}</div>
				{dynamicInput}
			</div>
		</Modal>
	)
}

InviteGuestModal.propTypes = {}

InviteGuestModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
	text: '',
	actionStatus: false,
}

export default InviteGuestModal
