import React from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import { FiCheckCircle } from 'react-icons/fi'
import { FiDollarSign } from 'react-icons/fi'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'
import Dropdown from '../forms/select/select'
import Input from '../forms/input/formik-input'
import { singleDonationSchema } from '../../utils/form-utils'
import {
	subscriptionInRealTimeDonate,
	updateSubscriptionInRealTimeDatabase,
} from '../../services/subscriptions'
import Button from '../forms/button/button'
const SingleDonationModal = ({
	opened,
	text,
	title,
	eventId,
	donationObject,
}) => {
	const { modal, user } = useHookstate(store)
	const submitting = useHookstate(false)
	const userEmail = user.email.get()
	const showSuccessMsg = useHookstate(false)
	const confirmDonation = useHookstate(false)
	const donationData = useHookstate({})

	const actionButtons = null

	const formik = useFormik({
		initialValues: {
			currency: 'USD',
			selectedOption: { currency: { label: 'USD', value: 'USD' } },
		},
		validationSchema: singleDonationSchema,
		validateOnChange: true,
		validateOnBlur: false,

		onSubmit: (data, { resetForm }) => {
			confirmDonation.set(true)
			donationData.set(data)
		},
	})

	const sendDonation = () => {
		confirmDonation.set(false)
		const { amount, currency } = donationData.get()

		let total_donation = (donationObject['total_donation'] || 0) + amount

		const payload = {
			donation: {
				total_donation,
				donated_amount: amount,
				donation_currency: currency,
			},
		}
		submitting.set(true)

		subscriptionInRealTimeDonate(eventId, userEmail, payload)
			.then((res) => {
				// Data saved successfully!
				submitting.set(false)
				showSuccessMsg.set(true)
			})
			.catch((error) => {
				submitting.set(false)
			})
	}

	const header = (
		<div className="flex flex-row justify-center items-center text-3xl bg-orange-400 text-white w-full">
			<div className="p-6">
				<FiDollarSign />
			</div>
			<div className="bg-orange-400 font-bold text-white text-3xl">{title}</div>
		</div>
	)
	return (
		<Modal
			header={header}
			actionButtons={actionButtons}
			opened={opened}
			icon={<GrClose onClick={() => modal.open.set(false)} />}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
				{/* <div className="text-gray-400">{text}</div> */}
				<form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
					{!showSuccessMsg.get() ? (
						confirmDonation.get() ? (
							<div className="w-full pl-10 pr-10">
								<p className="mb-10">
									Are you sure you want to make the donation of $
									{formik.values.amount}
								</p>

								<div className="control flex justify-around">
									<Button
										onClick={() => {
											sendDonation()
										}}
										label="Yes"
									/>
									<Button
										customStyle={'ml-10'}
										onClick={() => {
											confirmDonation.set(false)
										}}
										label="No"
									/>
								</div>
							</div>
						) : (
							<div className="w-full pl-10 pr-10">
								<div className="mb-5">
									<Dropdown
										selectedOption={formik.values.selectedOption?.currency}
										errorMessage={formik.errors.currency}
										placeholder="Currency"
										options={[{ value: 'USD', label: 'USD' }]}
										onChange={(option) => {
											formik.setFieldValue('currency', option.value)
											const selectedOption = formik.values.selectedOption
											selectedOption['currency'] = option
											formik.setFieldValue('selectedOption', selectedOption)
										}}
									/>
								</div>
								<div className="mb-5">
									<Input
										id="amount"
										name="amount"
										handleChange={formik.handleChange}
										value={formik.values.amount}
										errorMessage={formik.errors.amount}
										type="number"
										placeholder="Enter Amount"
									/>
								</div>

								<div className="mb-5">
									<button
										className={`${
											submitting.get() ? 'bg-orange-200' : 'bg-orange-500'
										} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
										type="submit"
									>
										{submitting.get() ? 'Sending...' : 'Send'}
									</button>
								</div>
							</div>
						)
					) : (
						<div className="w-full flex flex-col justify-center items-center">
							<FiCheckCircle size={50} color="green" />
							<p className="mt-5">Donation Successful</p>

							<button
								className={`mt-5 items-center text-orange-500 font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
								onClick={(e) => {
									e.preventDefault()
									showSuccessMsg.set(false)
									modal.open.set(false)
								}}
							>
								Continue
							</button>
						</div>
					)}
				</form>
			</div>
		</Modal>
	)
}

SingleDonationModal.propTypes = {}

SingleDonationModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
	text: '',
	actionStatus: false,
}

export default SingleDonationModal
