import {timeOptions} from "../../../constants";
import React from "react";
import PropTypes from "prop-types";
import Dropdown from "./select";
import {getTimezoneOptions} from "../../../utils/date-utils";

const timezoneOptions = getTimezoneOptions()

const TimeSelect = ({ readOnly, startTimeLabel, endTimeLabel, errorMessage, onStartTimeChange, onEndTimeChange, startTimePlaceholder, endTimePlaceholder, selectedStartTimeOption, selectedEndTimeOption }) => {
  
  const textSize =  readOnly ? "text-2xl" : "text-sm"
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">

      <div className="font-bold text-orange-400 ">{startTimeLabel}</div>
      <div className="font-bold text-orange-400 ">{endTimeLabel}</div>
      <div className={`${textSize} text-black`}>
        <div className="w-40">
          <div className="flex">
            <Dropdown
              readOnly={readOnly}
              selectedOption={selectedStartTimeOption}
              errorMessage={errorMessage?.time}
              placeholder={startTimePlaceholder}
              options={timeOptions}
              onChange={onStartTimeChange}
            />
          </div>
        </div>
      </div>
      <div className={`${textSize} text-black`}>
        <div className="w-40">
          <div className="flex">
            <Dropdown
              readOnly={readOnly}
              selectedOption={selectedEndTimeOption}
              errorMessage={errorMessage?.timezone}
              placeholder={endTimePlaceholder}
              options={timeOptions}
              onChange={onEndTimeChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

TimeSelect.defaultProps = {
  selectedStartTimeOption: null,
  selectedEndTimeOption: null,
  startTimePlaceholder: '',
  endTimePlaceholder: '',
  onEndTimeChange() {
  },
  onStartTimeChange() {
  },
  errorMessage: {},
  readOnly: false
}

TimeSelect.propTypes = {
  selectedStartTimeOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  selectedEndTimeOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  startTimePlaceholder: PropTypes.string,
  endTimePlaceholder: PropTypes.string,
  onStartTimeChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  errorMessage: PropTypes.shape({time: PropTypes.string, timezone: PropTypes.string}),
  readOnly: PropTypes.bool
}

export default TimeSelect
