import { initializeApp } from 'firebase/app'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AuthProvider } from './components/auth/auth-provider'
import RequireAuth from './components/auth/require-auth'
import Logo from './components/layout/logo2'
import SearchBar from './components/layout/search'
import Sidebar from './components/sidebar/sidebar'
import firebaseConfig from './firebase-exports'
import IndexPage from './pages'
import ContactPage from './pages/contact'
import FaqsPage from './pages/faqs'
import ForgetPassword from './pages/forget-password'
import JoinEvent from './pages/join-event'
import Login from './pages/login'
import NotFound from './pages/not-found'
import PasswordReset from './pages/password-reset'
import Home from './pages/protected/home'
import VerifyEmail from './pages/protected/verify-email'
import SignUp from './pages/sign-up'
import SupportPage from './pages/support'
import TermsAndConditions from './pages/terms-and-conditions'
import OngoingEvent from './pages/protected/event-ongoing'
import EventsOngoingSidebar from './components/sidebar/events-ongoing-sidebar'
import AlertTemplate from './components/alert-template/alert-template'
import store from './store/store'
import { useHookstate } from '@hookstate/core'
import Spinner from './components/spinner'
import Footer from './components/layout/footer'
import Events from './pages/protected/events'
import { getFirestore } from 'firebase/firestore'
import {
	initializeAppCheck,
	ReCaptchaEnterpriseProvider,
} from 'firebase/app-check'
import FundsSettingScreen from './pages/protected/funds-setting-screen'
import ChangeProfileScreen from './pages/protected/change-profile'
import ChangePasswordScreen from './pages/protected/change-password'
import Security from './pages/protected/security'

// const {
// 	initializeAppCheck,
// 	ReCaptchaEnterpriseProvider,
// } = require('firebase/app-check')
console.log(process.env.REACT_APP_CONTEXT)
export const app = initializeApp(firebaseConfig[process.env.REACT_APP_CONTEXT])

initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_ID),
	isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
})
// Initialize Cloud Firestore and get a reference to the service
export const firestoreRef = getFirestore(app)

const DefaultLayout = ({ children }) => {
	return (
		<div className="flex flex-row min-h-screen">
			{/* Sidebar */}

			<Sidebar />

			<div className="flex flex-col flex-grow md:ml-0 relative">
				<div className="flex flex-col flex-grow">
					{/* Header */}
					<div className="w-full flex  bg-white">
						<div className="w-full flex justify-start">
							<SearchBar />
						</div>
						<div className="w-full flex justify-end">
							<Logo />
						</div>
					</div>

					{/* Main Content */}
					<div className="">{children}</div>
				</div>

				{/* Footer */}
				<Footer />
			</div>
		</div>
	)
}

const EventsLayout = ({ children }) => {
	return (
		<div className="flex flex-row min-h-screen">
			{/* Sidebar */}

			<Sidebar />

			<div className="flex flex-col flex-grow md:ml-0 relative">
				<div className="flex flex-col flex-grow">
					{/* Header */}
					<div className="w-full flex bg-white">
						<div className="flex justify-end w-full">
							{/* You can adjust the Logo component accordingly */}
							<Logo className="w-auto h-12 md:h-auto" />
						</div>
					</div>

					{/* Main Content */}
					<div className="">{children}</div>
				</div>

				{/* Footer */}
				<Footer />
			</div>
		</div>
	)
}

const OngoingEventLayout = ({ children }) => {
	return (
		<>
			<EventsOngoingSidebar />
			<div className="relative md:ml-72 bg-blueGray-100">
				{/* Header */}
				<div className="w-full flex  bg-white"></div>
				<div className="mx-auto w-full">
					{children}
					{/* Footer */}
				</div>
			</div>
		</>
	)
}

const App = () => {
	const { alert, isLoading } = useHookstate(store)
	const alertData = alert.data.get()
	const toggleAlert = alert.show.get()

	const location = useLocation()
	let state = location.state
	// firebase.dynamicLinks().onLink((link) => {
	// 	console.log('Dynamic Link clicked:', link)
	// 	// Handle the link accordingly
	// })
	function isAndroid() {
		return /Android/i.test(navigator.userAgent)
	}

	function isIOS() {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent)
	}

	useEffect(() => {
		const checkAppInstalled = () => {
			if (isAndroid()) {
				checkInstallation('your-android-app://')
				console.log('your-android-app://')
			} else if (isIOS()) {
				console.log('https://apps.apple.com/gb/app/dynamo-mobile/id1481560195')
				checkInstallation(
					'https://apps.apple.com/gb/app/dynamo-mobile/id1481560195'
				)
			}
		}

		checkAppInstalled()
	}, [])

	const checkInstallation = (appScheme) => {
		const iframe = document.createElement('iframe')
		iframe.style.display = 'none'
		iframe.src = appScheme

		document.body.appendChild(iframe)

		setTimeout(() => {
			document.body.removeChild(iframe)
			//   setIsAppInstalled(true);
			console.log('trying to redirect')
			window.location.href = appScheme
		}, 2000) // Adjust the timeout as needed
	}

	useEffect(() => {
		if (toggleAlert === true) {
			setTimeout(function () {
				alert.set({ show: false })
			}, 3000)
		}
	}, [toggleAlert, alertData])

	useEffect(() => {
		initializeAppCheck(app, {
			provider: new ReCaptchaEnterpriseProvider(
				process.env.REACT_APP_RECAPTCHA_ID
			),
			isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
		})
	}, [])
	return (
		<AuthProvider>
			<AlertTemplate
				show={toggleAlert}
				message={alertData?.message}
				type={alertData?.type}
			/>
			<Spinner loading={isLoading.get()} />
			<Routes location={state?.backgroundLocation || location}>
				<Route path="/" element={<IndexPage />} />
				<Route path="/faqs" element={<FaqsPage />} />
				<Route path="/support" element={<SupportPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signUp" element={<SignUp />} />
				<Route path="/join/:eventId" element={<JoinEvent />} />
				<Route path="/TermsAndConditions" element={<TermsAndConditions />} />
				<Route path="/verifyemail" element={<VerifyEmail />} />
				<Route path="/passwordreset" element={<PasswordReset />} />

				<Route
					path="/home"
					element={
						<RequireAuth>
							<DefaultLayout>
								<Home />
							</DefaultLayout>
						</RequireAuth>
					}
				/>

				<Route
					path="/funds/settings"
					element={
						<RequireAuth>
							<EventsLayout>
								<FundsSettingScreen />
							</EventsLayout>
						</RequireAuth>
					}
				/>

				<Route
					path="/admin/settings"
					element={
						<RequireAuth>
							<EventsLayout>
								<Security />
							</EventsLayout>
						</RequireAuth>
					}
				/>

				<Route
					path="/admin/security"
					element={
						<RequireAuth>
							<EventsLayout>
								<Security />
							</EventsLayout>
						</RequireAuth>
					}
				/>

				<Route
					path="/events/:eventId"
					element={
						<OngoingEventLayout>
							<OngoingEvent />
						</OngoingEventLayout>
					}
				/>
				<Route
					path="/events"
					element={
						<RequireAuth>
							<EventsLayout>
								<Events />
							</EventsLayout>
						</RequireAuth>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Routes>

			{state?.backgroundLocation && (
				<Routes>
					<Route path="/forgetPassword" element={<ForgetPassword />} />

					<Route path="/signUp" element={<SignUp />} />
				</Routes>
			)}
		</AuthProvider>
	)
}

export default App
