/*eslint-disable*/
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import { AuthContext } from '../auth/auth-provider'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'

export default function Sidebar() {
	const [collapseShow, setCollapseShow] = React.useState('hidden')
	const { signout } = React.useContext(AuthContext)
	const { user } = useHookstate(store)
	const firstName = user.displayName.get()
	const email = user.email.get()
	const emailVerified = user.emailVerified.get()
	console.log('Image', user.photoURL.get())
	return (
		<nav className="md:left-0 md:block relative min-w-0">
			{emailVerified && (
				<>
					<div className="border-8 border-secondary-400 mx-0 md:top-5 md:bottom-0 z-10 py-4 px-6 bg-secondary-400 shadow-xl flex flex-wrap items-center justify-between z-10">
						<div className="flex flex-row gap-2">
							<img
								alt="..."
								className="w-28 h-28 object-cover rounded-full align-middle border-none"
								src={
									user.photoURL.get() ||
									require('../../assets/img/team-1-800x800.jpg')
								}
							/>
							<div className="flex flex-col content-center justify-center">
								<div className="text-white font-bold">Hi {firstName}</div>
								<div className="text-white">{email}</div>
							</div>
						</div>
					</div>
					<div className="bg-gray-50 md:overflow-y-auto md:h-screen md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative z-10 py-4">
						<div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
							{/* Toggler */}
							<button
								className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-black"
								type="button"
								onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
							>
								<FaBars />
							</button>
							{/* Brand */}
							{/*<div>*/}
							{/*  <NavLink activeClassName="bg-orange-500"*/}
							{/*    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"*/}
							{/*    to="/"*/}
							{/*  >*/}
							{/*    Hi Ayoola*/}
							{/*  </NavLink>*/}
							{/*</div>*/}

							{/* Collapse */}
							<div
								className={
									'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
									collapseShow
								}
							>
								{/* Collapse header */}
								<div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
									<div className="flex flex-wrap">
										<div className="w-6/12">
											<NavLink
												activeClassName="bg-orange-500"
												className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
												to="/"
											>
												LOGO HERE
											</NavLink>
										</div>
										<div className="w-6/12 flex justify-end">
											<button
												type="button"
												className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
												onClick={() => setCollapseShow('hidden')}
											>
												<FaTimes />
											</button>
										</div>
									</div>
								</div>

								{/* Heading */}

								{/* Navigation */}

								<ul className="md:flex-col md:min-w-full flex flex-col list-none">
									<li className="items-center ">
										<NavLink
											activeClassName="bg-orange-500"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/home') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/home"
										>
											<i
												className={
													window.location.href.indexOf('/admin/dashboard') !==
													-1
														? 'opacity-75'
														: 'text-blueGray-300'
												}
											></i>{' '}
											Dashboard
										</NavLink>
									</li>

									{/* <li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/admin/settings') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/admin/settings"
										>
											<i
												className={
													window.location.href.indexOf('/admin/settings') !== -1
														? 'opacity-75'
														: 'text-blueGray-300'
												}
											></i>{' '}
											Change Profile
										</NavLink>
									</li> */}
									{/* Divider */}
									{/* <hr className="my-4 md:min-w-full" /> */}
									{/* Navigation */}

									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											// className="text-gray-700 hover:text-blueGray-500 text-xs py-3 block"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/admin/settings') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/admin/settings"
										>
											Profile Settings
										</NavLink>
									</li>

									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/funds/settings') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/funds/settings"
										>
											<i
												className={
													window.location.href.indexOf('/funds/settings') !== -1
														? 'opacity-75'
														: 'text-blueGray-300'
												}
											></i>{' '}
											Funds Settings
										</NavLink>
									</li>

									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/admin/tables') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/admin/tables"
										>
											<i
												className={
													window.location.href.indexOf('/admin/tables') !== -1
														? 'opacity-75'
														: 'text-blueGray-300'
												}
											></i>{' '}
											Notifications
										</NavLink>
									</li>

									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/events') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/events"
										>
											<i
												className={
													window.location.href.indexOf('/admin/maps') !== -1
														? 'opacity-75'
														: 'text-blueGray-300'
												}
											></i>{' '}
											Events
										</NavLink>
									</li>
								</ul>

								{/* Divider */}
								<hr className="my-4 md:min-w-full" />
								{/* Navigation */}

								<ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											// className="text-gray-700 hover:text-gray-500 text-xs py-3 block"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/landing') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/landing"
										>
											Privacy Policy
										</NavLink>
									</li>

									<li className="items-center">
										<NavLink
											activeClassName="bg-orange-500"
											// className="text-gray-700 hover:text-gray-500 text-xs py-3 block"
											className={
												'text-xs py-3 block ' +
												(window.location.href.indexOf('/login') !== -1
													? 'bg-orange-500 text-white px-6'
													: 'text-gray-700 hover:text-gray-500 px-6')
											}
											to="/login"
											onClick={() => {
												signout()
											}}
										>
											Sign Out
										</NavLink>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</>
			)}
		</nav>
	)
}
