import { useHookstate } from '@hookstate/core'
import { ErrorMessage, useField } from 'formik'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

const PasswordInput = ({
	name,
	label,
	type,
	errorMessage,
	className,
	onChange,
	handleChange,

	...props
}) => {
	const showPassword = useHookstate(false)
	const dynamicType = showPassword.get() ? 'text' : 'password'
	return (
		<div className="mb-4">
			{/*<label className="block text-gray-700 text-sm font-bold" for={field.name}>*/}
			{/*  {label}*/}
			{/*</label>*/}
			{type === 'password' && (
				<div className="relative">
					<input
						{...props}
						type={dynamicType}
						value={props.value}
						onChange={handleChange}
						className={`${
							errorMessage != '' ? 'border border-red-500' : ''
						} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline w-full px-6 py-6 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic ${className}`}
					/>
					<div
						onClick={() => {
							showPassword.set(!showPassword.get())
						}}
						className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
					>
						{showPassword.get() ? <BsEyeSlash /> : <BsEye />}
					</div>
				</div>
			)}
			{type !== 'password' && (
				<input
					{...props}
					value={props.value}
					onChange={handleChange}
					className={`${
						errorMessage ? 'border-red-500' : ''
					} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline w-full px-6 py-6 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic ${className}`}
				/>
			)}
			{errorMessage ? (
				<p className="text-red-500 mt-1 text-sm">{errorMessage}</p>
			) : null}
		</div>
	)
}

export default PasswordInput
