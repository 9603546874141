import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from 'firebase/storage'

export const uploadFile = async ({ path, uid, onUploadSuccess, onError }) => {
	try {
		const storage = getStorage()
		const storageRef = ref(storage, `images/profile/${uid}${path}`)
		const file = new File([path], 'image')

		const uploadTask = uploadBytesResumable(storageRef, file)

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Handle progress, pause, and resume states
			},
			(error) => {
				// Handle unsuccessful uploads

				onError(error)
			},
			() => {
				// Handle successful uploads on complete
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log('downloadURL', downloadURL)
					onUploadSuccess(downloadURL)
				})
			}
		)
	} catch (e) {
		console.error('****Error uploading image*****')
		console.error(e)
	}
}
