import React from 'react'
import { Formik, Form, useField, ErrorMessage } from 'formik'
import { useHookstate } from '@hookstate/core'
import { object, string, ref } from 'yup'
import { AuthContext } from '../components/auth/auth-provider'
import store from '../store/store'
import { useLocation, useNavigate } from 'react-router-dom'
import ModalDeprecated from '../components/modal/modal-deprecated'
import { useAlert } from 'react-alert'
const PasswordReset = () => {
	let navigate = useNavigate()
	let location = useLocation()
	const alert = useAlert()
	const { actionCode } = location.state
	const initialValues = {
		code: actionCode,
		password: '',
		passwordConfirmation: '',
	}

	const { globalError } = useHookstate(store)
	const { PasswordResetConfirmation } = React.useContext(AuthContext)
	const loading = useHookstate(false)
	const error = useHookstate('')
	const hasError = useHookstate(false)
	const { code } = globalError.auth.get()
	if (code === 'EXPIRED_OOB_CODE') {
		error.set('Your Reset Link has already expired. Please try again')
	} else {
		error.set('error resetting password!')
	}

	const onSubmit = async (values) => {
		if (!hasError.get()) {
			loading.set(true)
			error.set('')
			PasswordResetConfirmation(values, () => {
				loading.set(false)
				alert.show('Your Password has been Successfully Reset', {
					type: 'success',
				})
				navigate('/login')
			})
		}
	}
	return (
		<ModalDeprecated
			opened={true}
			allowSubmit={!hasError}
			onSubmit={onSubmit}
			onClose={() => {
				navigate(-1)
			}}
			showActionButtons={false}
		>
			<div className="h-50 mt-0 flex items-center justify-center flex-col ">
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={resetPasswordValidation}
				>
					{({ errors }) => {
						hasError.set(Object.keys(errors).length > 0)
						return (
							<Form className="w-3/4 shadow-md rounded px-8 pt-6 pb-8 bg-gray-100">
								<div className="font-medium text-gray-600 pb-4">
									RESET PASSWORD?
								</div>
								<Input name="password" placeholder="Password" type="password" />
								<Input
									name="passwordConfirmation"
									placeholder="Re-enter Password"
									type="password"
								/>
								<div className="flex items-center justify-between">
									<button
										className="bg-orange-500 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
										type="submit"
									>
										Reset Password
									</button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</ModalDeprecated>
	)
}

const resetPasswordValidation = object().shape({
	password: string()
		.min(8, 'please enter a minimum of 8 characters')
		.required('password is required'),
	passwordConfirmation: string().oneOf(
		[ref('password'), null, ''],
		'Passwords must match'
	),
})

const Input = ({ name, label, ...props }) => {
	const [field, meta] = useField(name)
	return (
		<div className="mb-4">
			<input
				className={`${
					meta.error && meta.touched ? 'border-red-500' : ''
				} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline`}
				{...field}
				{...props}
			/>
			<ErrorMessage
				name={field.name}
				component="div"
				className="text-red-500 text-xs"
			/>
		</div>
	)
}

export default PasswordReset
