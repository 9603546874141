import { getFunctions, httpsCallable } from 'firebase/functions'
import { ErrorHandler } from '../utils/service-utils'
import store from '../store/store'
import { none } from '@hookstate/core'
import { retrieveSubscriptions } from './subscriptions'
import { getQuery } from '../utils/firestore-util'
import { applyFiltersAndPagination, getEventObject } from '../utils/event-utils'
import { firestoreRef } from '../routes'
import {
	collection,
	query,
	getDocs,
	doc,
	getDoc,
	getFirestore,
	orderBy,
	where,
	limit,
	updateDoc,
	serverTimestamp,
	setDoc,
} from 'firebase/firestore'
import { eventStatus } from '../constants'

export const retrieveEvents = (options) => {
	getEventList(options)
		.then(async (result) => {
			// Assuming you have a "store.events" object for storing events
			store.events.set(result)
			store.event.loading.set(false)
			await retrieveSubscriptions(options)
		})
		.catch((error) => {
			ErrorHandler(error)
		})
}

const getEventList = async (options) => {
	try {
		const { uid } = options

		const filter = options?.filter
		const criteria = filter?.criteria
		const nowAndFuture = filter?.nowAndFuture
		const status = filter?.status
		const statuses = getStatuses(['active', 'started'], status)

		let q = query(
			collection(firestoreRef, 'user-events'),
			where('uid', '==', uid),
			where('status', 'in', statuses),
			orderBy('unixTimestamp', 'desc')
		)

		if (criteria?.length > 0) {
			q = getQuery(criteria, q)
		} else {
			// q = query(q, orderBy('unixTimestamp', 'desc'))
		}

		const events = await getEventObject(q, nowAndFuture)
		return events
	} catch (error) {
		console.log('+++++', error)
		throw error
	}
}

const getStatuses = (defaultStatuses, status) => {
	if (status === 'both' || !status) {
		return defaultStatuses
	}
	return [status]
}

export const retrievePaginatedEventsList = async (options) => {
	try {
		const { paginate } = options
		const {
			next,
			limit: pageLimit,
			searchTerm,
			orderBy: orderByField,
			orderDirection,
		} = paginate

		// Create a reference to the 'user-events' collection
		const eventsCollection = collection(firestoreRef, 'user-events')

		// Create base filters
		const filters = [where('uid', '==', options.uid)]

		// Create orderBy clause for applyFiltersAndPagination
		const orderByClause = orderBy(orderByField, orderDirection)

		// Retrieve paginated data using the reusable function
		const { data, next: nextToken } = await applyFiltersAndPagination(
			eventsCollection,
			filters,
			orderByClause,
			pageLimit,
			next,
			searchTerm
		)

		const sortedEvent = data.sort((a, b) => {
			const dateA = new Date(a.createdAt)
			const dateB = new Date(b.createdAt)

			return dateA - dateB
		})

		// Update your store with the retrieved data and paginate information
		if (next) {
			store.paginatedEvents.data.merge(sortedEvent)
		} else {
			store.paginatedEvents.data.set(sortedEvent)
		}
		store.paginatedEvents.paginate.set({ next: nextToken })
		store.paginatedEvents.loading.set(false)
	} catch (error) {
		console.error('Error retrieving paginated events:', error)
		store.paginatedEvents.loading.set(false)
		// Handle errors as needed
	}
}

export const retrieveEmails = async (email, onSuccess, onFailure) => {
	try {
		const db = getFirestore()
		const q = query(
			collection(db, 'users-info'),
			where('email', '>=', email),
			where('email', '<', `${email}\uf8ff`),
			limit(10)
		)

		const querySnapshot = await getDocs(q)
		const data = querySnapshot.docs.map((doc) => doc.data())
		onSuccess(data)
	} catch (error) {
		console.error(`Error UPDATE event: ${error}`)
		if (onFailure) {
			if (error instanceof Error) {
				onFailure(error)
			} else {
				throw error
			}
		}
	}
}

export const retrieveSearchableEventsList = async (searchTerm, onSuccess) => {
	try {
		const firestoreInstance = getFirestore() // Initialize Firestore instance if not done already

		// Create a reference to the 'user-events' collection
		const eventsCollection = collection(firestoreInstance, 'user-events')

		// Create a query with search term filter
		let q = query(
			eventsCollection,
			where('eventName', '>=', searchTerm.toLowerCase()),
			where('eventName', '<=', searchTerm.toLowerCase() + '\uf8ff')
		)

		// Fetch the documents matching the query
		const querySnapshot = await getDocs(q)

		// Extract event data from the query results
		const data = querySnapshot.docs.map((doc) => doc.data())

		// Update your store with the retrieved data
		store.searchableEvents.set(data)

		if (onSuccess) {
			onSuccess(data)
		}
	} catch (error) {
		console.error('Error retrieving searchable events:', error)
		// Handle errors as needed
	}
}

export const retrieveEvent = (id, onSuccess) => {
	retrieveEventById(id)
		.then((result) => {
			console.log('__log here', result)
			if (onSuccess) {
				onSuccess(result)
			}
		})
		.catch((error) => {
			ErrorHandler(error)
		})
}

// Define the retrieveEvent function as needed
export const retrieveEventById = async (eventId) => {
	// Implement your logic to retrieve an event based on the provided eventId
	// This is a placeholder; you should replace it with your actual implementation
	try {
		const eventDoc = await doc(firestoreRef, 'user-events', eventId)
		const eventSnapshot = await getDoc(eventDoc)

		if (eventSnapshot.exists()) {
			const eventObject = eventSnapshot.data()

			if (eventObject.uid === store.user.uid.get()) {
				eventObject['isHost'] = true
			}
			return eventObject
		} else {
			// Handle the case where the event does not exist
			return null
		}
	} catch (error) {
		console.log('__log here', error)
		throw error
	}
}

export const createEvent = async (data, onSuccess, onFailure) => {
	try {
		// Create a reference to the 'user-events' collection
		const eventsCollection = collection(firestoreRef, 'user-events')
		const docRef = doc(eventsCollection)

		const event = {
			...data,
			url: '',
			updatedAt: null,
			updatedBy: null,
			createdAt: serverTimestamp(),
			isDeleted: false,
			deletedAt: null,
			deletedBy: null,
			id: docRef.id,
			uid: store.user.uid.get(),
			status: 'active',
			eventName: data['eventName']?.toLowerCase(),
			onGoingStatus: 'not_started',
		}
		console.log('got here now', event)

		if (!event.endDate) {
			event['endDate'] = event.date
			event['endDateJsonDate'] = event.jsonDate
			event['endDateUnixTimestamp'] = event.unixTimestamp
		}

		await setDoc(docRef, event, { merge: true })
		// Merge the created event into the store or handle it as needed
		store.events.merge([event])

		if (onSuccess) {
			onSuccess(event)
		}
	} catch (error) {
		console.error('Error creating event:', error)
		if (onFailure) {
			onFailure(error)
		}
	}
}

export const updateEvent = async (data, onSuccess, onFailure) => {
	try {
		// Extract fields that should not be updated from the provided data
		const { id, uid, createdAt, ...updateData } = data

		const { eventName, date, onGoingStatus } = updateData

		// Convert the event name to lowercase if it's provided
		if (eventName) {
			updateData.eventName = eventName.toLowerCase()
		}

		// If a date is provided, create a timestamp field
		let timestampField = {}
		if (date) {
			timestampField = {
				timestamp: new Date(date),
			}
		}

		// Determine the 'attendees' value based on the event status
		let attendees = 'Not Available'
		if (onGoingStatus === eventStatus.ENDED) {
			attendees = await getNumberOfAttendees(id)
		}

		// Create a reference to the document in the 'user-events' collection
		const eventDocRef = doc(firestoreRef, 'user-events', id)

		// Update the document with the provided data
		await updateDoc(eventDocRef, {
			...updateData,
			...timestampField,
			attendees,
			updatedAt: new Date(),
			updatedBy: uid,
		})

		// Merge the updated data into your store based on your store's structure
		const { event, events, onGoingEvents } = store
		events[event.selectedEvent.get()].merge(updateData)

		onGoingEvents[id].merge(updateData)

		if (onSuccess) {
			onSuccess(updateData)
		}
	} catch (error) {
		console.error('Error updating event:', error)
		if (onFailure) {
			onFailure(error)
		}
	}
}

export const getNumberOfAttendees = async (eventId) => {
	try {
		// Define the statuses to include in the query
		const statuses = [eventStatus.JOINED, eventStatus.LEFT_EVENT]

		// Create a reference to the 'user-event-subscriptions' collection
		const subscriptionsCollection = collection(
			firestoreRef,
			'user-event-subscriptions'
		)

		// Create a query to filter subscriptions by 'eventId' and 'status'
		const subscriptionsQuery = query(
			subscriptionsCollection,
			where('eventId', '==', eventId),
			where('status', 'in', statuses)
		)

		// Get the documents matching the query
		const querySnapshot = await getDocs(subscriptionsQuery)

		// Calculate the number of attendees by counting the documents
		return querySnapshot.docs.length
	} catch (error) {
		console.error('Error fetching number of attendees:', error)
		// You may handle the error here or throw it further if needed.
	}
}

export const deleteEvent = (data, onSuccess, onFailure) => {
	const update = httpsCallable(getFunctions(), 'events-updateEvent')
	//
	let index = store.event.selectedEvent.get()
	store.event.selectedEvent.set(0)
	store.events[index].set(none)
	update(data)
		.then((result) => {
			if (onSuccess) {
				store.isEventDeleted.set(false)
				onSuccess(result)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
			if (onFailure) {
				onFailure()
			}
		})
}
