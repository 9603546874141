import React, { useState, useEffect, useRef } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import PaymentACHCard from '../../components/PaymentACHCard'
import {
	exchangeTokens,
	getPlaidLinkToken,
	retrievePaymentMethods,
} from '../../services/funds'
import { getIpAddress } from '../../utils/ip-util'
import OpenPaymentMethodModal from '../../components/modal/open-payment-method-modal'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import Loader from '../../components/loader'
import { TabHeader } from '../../components/card-tab/tab-header'
import { TabBody } from '../../components/card-tab/tab-body'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { FaMoneyCheck } from 'react-icons/fa'
const FundsSettingScreen = () => {
	const fundDirection = useRef('')
	const { modal, PaymentMethod } = useHookstate(store)
	const {
		all = [],
		defaultHostPaymentMethod = {},
		defaultGuestPaymentMethod = {},
	} = PaymentMethod.get()

	const [linkConfiguration, setLinkConfiguration] = useState(null)
	const [linkTokenStatus, setLinkTokenStatus] = useState('')

	const [showSpinner, setShowSpinner] = useState(false)
	// const [fundDirection, setFundDirection] = useState('')
	const [ip, setIp] = useState('')
	const [hostBalance, setHostBalance] = useState(0)
	const [guestBalance, setGuestBalance] = useState(0)
	const [linkToken, setLinkToken] = useState('')
	const [tab, setTab] = useState()
	const modalData = modal.data.get()

	useEffect(() => {
		getDetails()
	}, [])

	const onEvent = (event) => {
		const name = event.name
		const metadata = event.metadata.description()
		console.log(`onEvent: ${name}, metadata: ${metadata}`)
	}

	const onSuccess = async (public_token, metadata) => {
		console.log('link retrieved successfully', public_token)
		const publicToken = public_token
		const accountId = metadata.accounts[0].id
		console.log('publicToken', {
			publicToken,
			accountId,
			fundDirection: fundDirection.current,
			ip: ip.toString(),
		})
		await exchangeTokens({
			publicToken,
			accountId,
			fundType: 'ach',
			fundDirection: fundDirection.current,
			ip: ip.toString(),
		})

		await _retrievePaymentMethods()
	}

	const onExit = (event) => {
		const metadata = event.metadata.description()
		const error = event.error?.description()
		console.log(`onExit metadata: ${metadata}, error: ${error}`)
	}

	const getDetails = async () => {
		setShowSpinner(true)

		await _retrievePaymentMethods()

		const ip = await getIpAddress()

		setIp(ip)
		await _retrieveLinkToken()
		setShowSpinner(false)
	}

	const _retrievePaymentMethods = async () => {
		try {
			const paymentMethods = await retrievePaymentMethods('')
		} catch (error) {
			console.error('Unable to retrieve payment methods:', error)
		}
	}

	const _retrieveLinkToken = async () => {
		try {
			const linkToken = await getPlaidLinkToken({
				onSuccess: (m) => setLinkTokenStatus(m),
				onError: (m) => setLinkTokenStatus(m),
			})
			setLinkToken(linkToken)
			setLinkConfiguration({
				token: linkToken,
			})
		} catch (error) {
			console.error('Error retrieving link token:', error)
		}
	}

	const openPaymentMethodModal = (title, type) => {
		modal.open.set(true)
		modal.id.set('payment-methods')
		modal.data.set({ title, type, id: 'payment-methods' })
	}

	const { open, ready } = usePlaidLink({
		token: linkToken,
		onSuccess: onSuccess,
	})

	console.log('modalData', modalData)

	if (showSpinner) {
		return (
			<div className="flex flex-col items-center justify-center min-h-[400px]">
				<Loader style={''} />
			</div>
		)
	}

	const IncomingFunds = () => {
		return (
			<div className="mb-8  flex flex-col items-center justify-center">
				{/* <h2 className="text-xl font-semibold ">Host (Incoming Funds)</h2> */}

				{/* Display host payment method card */}
				{Object.keys(defaultHostPaymentMethod).length > 0 ? (
					<div className="flex flex-col items-center justify-center">
						<PaymentACHCard
							paymentMethod={defaultHostPaymentMethod}
							type="credit"
							guestBalance={hostBalance}
						/>
						<button
							className="mt-4 bg-orange-500 text-white py-2 px-4 rounded"
							onClick={() => {
								fundDirection.current = 'in-host'
								openPaymentMethodModal(
									'Select Account for Receiving Funds',
									'credit'
								)
							}}
						>
							Change Payment Method
						</button>
					</div>
				) : (
					<div className="mb-8  flex flex-col items-center justify-center">
						{all.length === 0 && (
							<>
								<p>You have not set up a method for receiving funds.</p>
								<button
									className={`mt-4 bg-orange-500 text-white py-2 px-4 rounded ${
										linkConfiguration ? '' : 'opacity-50 cursor-not-allowed'
									}`}
									onClick={() =>
										linkConfiguration
											? (() => {
													// setFundDirection('in-host')
													fundDirection.current = 'in-host'
													open()
											  })()
											: null
									}
									disabled={!linkConfiguration}
								>
									Add new Payment Method
								</button>
							</>
						)}
						{all.length > 0 && (
							<>
								<p>Select a default card from card list.</p>
								<button
									className="mt-4 bg-orange-500 text-white py-2 px-4 rounded"
									onClick={() => {
										fundDirection.current = 'in-host'
										openPaymentMethodModal(
											'Select Account for Receiving Funds',
											'credit'
										)
									}}
								>
									Add Payment Method
								</button>
							</>
						)}
					</div>
				)}
			</div>
		)
	}

	const OutgoingFunds = () => {
		return (
			<div className="mb-8  flex flex-col items-center justify-center">
				{/* <h2 className="text-xl font-semibold mb-4">Guest (Outgoing Funds)</h2> */}

				{/* Display guest payment method card */}
				{Object.keys(defaultGuestPaymentMethod).length > 0 ? (
					<div className="mb-8  flex flex-col items-center justify-center">
						<PaymentACHCard
							paymentMethod={defaultGuestPaymentMethod}
							type="debit"
							guestBalance={guestBalance}
						/>
						<button
							className="mt-4 bg-orange-500 text-white py-2 px-4 rounded"
							onClick={() => {
								fundDirection.current = 'out-guest'
								openPaymentMethodModal(
									'Select Account for Giving Donations',
									'debit'
								)
							}}
						>
							Change Payment Method
						</button>
					</div>
				) : (
					<div className="mb-8  flex flex-col items-center justify-center">
						{all.length === 0 && (
							<>
								<p>You have not set up a method for giving funds.</p>
								<button
									className={`mt-4 bg-orange-500 text-white py-2 px-4 rounded ${
										linkConfiguration ? '' : 'opacity-50 cursor-not-allowed'
									}`}
									onClick={() =>
										linkConfiguration
											? (() => {
													// setFundDirection('out-guest')
													fundDirection.current = 'out-guest'
													open()
											  })()
											: null
									}
									disabled={!ready}
								>
									Add new Payment Method
								</button>
							</>
						)}
						{all.length > 0 && (
							<>
								<p>Select a default card from card list.</p>
								<button
									className="mt-4 bg-orange-500 text-white py-2 px-4 rounded"
									onClick={() => {
										fundDirection.current = 'out-guest'
										openPaymentMethodModal(
											'Select Account for Giving Donations',
											'debit'
										)
									}}
								>
									Add Payment Method
								</button>
							</>
						)}
					</div>
				)}
			</div>
		)
	}

	return (
		<>
			{modalData?.id === 'payment-methods' && (
				<OpenPaymentMethodModal
					opened={modal?.open.get()}
					text={`Payment methods`}
					title="Payment methods"
					type={modal?.data?.get()?.type}
					actionStatus={false}
					addPaymentMethod={() => {
						open()
					}}
				/>
			)}

			<div className="container p-4 flex  flex-col items-start justify-center">
				<TabHeader
					items={[
						{
							title: 'Incoming Funds',
							description: 'Bank to receive funds from event',
							icon: <BiMoneyWithdraw size={50} color="#6f7073" />,
							key: 'incoming-funds',
						},
						{
							title: 'Outgoing Funds',
							description: 'Bank to spend from',
							icon: <FaMoneyCheck size={50} color="#6f7073" />,
							key: 'outgoing-funds',
						},
					]}
					setTab={setTab}
					tab={tab}
				/>
				<TabBody
					items={{
						'incoming-funds': <IncomingFunds />,
						'outgoing-funds': <OutgoingFunds />,
					}}
					tab={tab}
				/>
			</div>
		</>
	)
}

export default FundsSettingScreen
