import { where } from 'firebase/firestore'

export const getQuery = (criteria, query) => {
	return criteria.reduce((accQuery, criterion) => {
		if (criterion.field !== 'status') {
			accQuery = where(accQuery, criterion.field, criterion.op, criterion.value)
		}
		return accQuery
	}, query)
}
