import { hookstate } from '@hookstate/core'

export const initial = {
	user: {
		email: '',
		isAuthenticated: false,
		emailVerified: false,
		displayName: '',
		anon: false,
		uid: '',
		bio: '',
		photoURL: '',
	},
	event: {
		loading: false,
		selectedEvent: 0,
		editMode: false,
		guestEditMode: false,
	},
	subscriptions: { paginate: {}, data: [], loading: true },
	onGoingEvents: {},
	eventDetail: {},
	events: [],
	paginatedEvents: { paginate: {}, data: [], loading: true },
	participants: [],
	attendees: [],
	contribution: 0,
	searchableEvents: [],
	modal: { open: false, id: null, data: {} },
	alert: { show: false, data: {} },
	callback: () => {},
	globalError: { auth: { code: '' } },
	isEventDeleted: false,
	isLoading: false,
	enableShare: false,
	sharedAll: false,
	PaymentMethod: {
		all: [],
		defaultHostPaymentMethod: {},
		defaultGuestPaymentMethod: {},
		loadingPayment: false,
	},
	LinkToken: '',
}
const store = hookstate(initial)

export default store
