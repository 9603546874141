import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import store from '../../store/store'
import { useHookstate } from '@hookstate/core'
import { AuthContext } from './auth-provider'

export default function RequireAuth({ children, ...rest }) {
	let { user, globalError } = useHookstate(store)
	const userState = JSON.parse(localStorage.getItem('user')) || {}
	const { anonsignin } = useContext(AuthContext)
	React.useEffect(() => {
		user.set({ ...userState })
	}, [userState])

	const { isAuthenticated = false, anon, emailVerified } = userState

	let location = useLocation()

	try {
		//TODO: if not verfied got navigate to page
		if (!isAuthenticated || anon) {
			return <Navigate to="/login" state={{ from: location }} />
		}
		if (emailVerified === false) {
			return <Navigate to="/verifyemail" state={{ from: location }} />
		}

		return children
	} catch (e) {
		console.log(e)
		return null
	}
}
