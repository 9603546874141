import React from 'react'

const Ellipses = ({ str, length }) => {
	const [readMore, setReadMore] = React.useState(false)
	return (
		<p className="text-sm">
			{str && str.length > length ? (
				<>
					{readMore ? str : str.slice(0, length)}
					<button
						className="p-0 font-normal rounded-none ml-1"
						onClick={() => setReadMore(!readMore)}
					>
						{readMore ? ' hide' : '...'}
					</button>
				</>
			) : (
				str
			)}
		</p>
	)
}

Ellipses.defaultProps = {
	length: 150,
}

export default Ellipses
