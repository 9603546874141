import React from 'react'
import SocialLinks from './social-links'
import NavLinks from './nav-links'

export default function Footer() {
	return (
		<footer className="text-sm leading-5 tracking-normal text-gray-400 mt-auto">
			<div className="w-full max-w-6xl px-6 mx-auto mt-auto">
				<div className="relative flex flex-wrap py-10 lg:justify-between mt-auto">
					<NavLinks className="inline-flex justify-center flex-none w-full mb-6 lg:flex lg:w-1/2 lg:justify-end lg:order-1 lg:mb-0" />
					<SocialLinks className="inline-flex justify-center flex-none w-full mb-6 lg:flex lg:justify-end sm:flex sm:justify-end" />
					<div className="inline-flex justify-center flex-none w-full mb-6 lg:w-1/2 lg:justify-start absolute bottom-0 ">
						v{process.env.REACT_APP_VERSION}. &copy; {new Date().getFullYear()}{' '}
						Dynamo Innovations LLC, all rights reserved.
					</div>
				</div>
			</div>
		</footer>
	)
}
