import React from 'react'
import { WithContext as ReactTagInput } from 'react-tag-input'
import EventDetail from '../../event-details-card/event-detail'
import PropTypes from 'prop-types'
import { useHookstate } from '@hookstate/core'

const KeyCodes = {
	comma: 188,
	enter: 13,
	semicolon: 186,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.semicolon]

const EmailTagInput = ({
	placeholder,
	error,
	clearError,
	isSubmitting,
	onSubmit,
	validate,
	suggestedEmails = [],
	clearSuggestion = () => {},
	onInputChange = () => {},
	inputValue = '',
}) => {
	const tags = useHookstate(new Set())
	const tagsObject = tags.get({ noproxy: true })
	const errorText = useHookstate('')
	const reset = useHookstate(false)
	const inputText = useHookstate('')
	const izSubmitting = useHookstate(false)

	if (reset.get()) {
		tags.set(new Set())
		errorText.set('')
		clearError()
		document.getElementsByClassName('ReactTags__tagInputField')[0].value = ''
		reset.set(false)
	}

	const handleDelete = (i, j) => {
		const t = [...tagsObject].filter((tag, index) => index !== i)
		tags.set(new Set(t))
		errorText.set('')
		clearError()
	}

	const handleAddition = (tag) => {
		console.log('add tag', tag)
		tagsObject.add(tag.text)
		tags.set(tagsObject)
		errorText.set('')
		clearError()
	}

	const handleSelection = (email) => {
		tagsObject.add(email)
		tags.set(tagsObject)
		errorText.set('')
		clearError()
		clearSuggestion([])
	}

	const handleTagClick = (index) => {
		console.log('The tag at index ' + index + ' was clicked')
	}

	return (
		<>
			<ReactTagInput
				placeholder={placeholder}
				tags={[...tagsObject].map((t) => ({ text: t, id: t }))}
				suggestions={[]}
				delimiters={delimiters}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleTagClick={handleTagClick}
				inputFieldPosition="bottom"
				autofocus
				inputValue={inputValue}
				allowDragDrop={false}
				clearAll={true}
				onClearAll={() => {
					reset.set(true)
				}}
				handleInputChange={(e) => {
					reset.set(false)
					errorText.set('')
					inputText.set(e)
					onInputChange(e)
				}}
			/>
			{suggestedEmails.length > 0 && (
				<div className="suggestion-list absolute bg-white shadow-md rounded mt-2 z-10">
					{suggestedEmails.map(({ fullName, email }) => (
						<div
							key={email}
							className="py-2 px-4 cursor-pointer hover:bg-gray-100"
							onClick={() => {
								handleSelection(email)
								inputText.set('')
								onInputChange('')
							}}
						>
							{fullName || email}
						</div>
					))}
				</div>
			)}
			<div className="text-red-500 text-xs">{errorText.get() || error}</div>
			<div className="text-center my-10">
				<button
					disabled={error || isSubmitting}
					className={`${isSubmitting ? 'bg-orange-200' : 'bg-orange-500'} ${
						error ? 'cursor-not-allowed' : ''
					} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
					onClick={() => {
						const { valid, errorMessage } = validate(inputText.get())
						if (inputText.get() && errorMessage) {
							errorText.set(errorMessage)
						} else if (inputText.get() && valid) {
							tagsObject.add(inputText.get())
							inputText.set('')
						}

						console.log('see me!', tagsObject, valid)
						if (!errorText.get()) {
							onSubmit([...tagsObject], () => {
								reset.set(true)
							})
						}
					}}
				>
					{isSubmitting ? 'Sending Invitation...' : 'Send Invitation'}
				</button>
			</div>
		</>
	)
}

EventDetail.defaultProps = {
	isSubmitting: false,
	isSubmitted: false,
	error: '',
	placeholder: '',
	onSubmit: () => {},
	validate: () => {},
}

EventDetail.propTypes = {
	placeholder: PropTypes.string,
	isSubmitting: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	error: PropTypes.string,
	onSubmit: PropTypes.func,
	validate: PropTypes.func,
}

export default EmailTagInput
