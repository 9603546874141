import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import { eventStatus, modalID } from '../../constants'
import Input from '../forms/input/formik-input'
import { updateSubscription } from '../../services/subscriptions'
import { FiCheck, FiLoader, FiX } from 'react-icons/fi'
import { checkStatus } from '../../utils/event-utils'
import './style.css'

export default function Guest({
	participant,
	enableShare,
	isHost,
	event,
	showMenu,
	setShowMenu,
}) {
	const { modal, eventDetail, isLoading, alert } = useHookstate(store)
	const eventObject = eventDetail.get()
	const {
		email = '',
		guestDisplayName = '',
		donation = 0,
		status = 'started',
		resentInvite = 0,
	} = participant
	const { donated_amount = 0, total_donation = 0 } = donation
	const participantStatus =
		status === eventStatus.JOINED
			? 'bg-green-500'
			: status === eventStatus.LEFT_EVENT
			? 'bg-yellow-300'
			: 'bg-gray-300'
	const [submitting, setSubmitting] = React.useState(false)
	const [donationMade, setDonationMade] = React.useState(false)
	const [donationAmount, setDonationAmount] = React.useState(total_donation)

	useEffect(() => {
		setDonationAmount(donated_amount)
		handleDonation()
	}, [donated_amount, total_donation])

	const handleDonation = () => {
		setDonationMade(true)
		setTimeout(() => {
			setDonationMade(false)
			setDonationAmount(total_donation)
		}, 2000) // Wait for 2 seconds before hiding the bubble
	}

	const acceptRequest = () => {
		setSubmitting(true)
		updateSubscription(
			{
				eventId: event.id,
				email,
				status: eventStatus.REQUEST_ACCEPTED,
			},
			onSuccessfulJoined,
			onJoinedFailure
		)
	}

	const rejectRequest = () => {
		setSubmitting(true)
		updateSubscription(
			{
				eventId: event.id,
				email,
				status: eventStatus.REQUEST_REJECTED,
			},
			(res) => {
				setSubmitting(false)
				alert.set({
					show: true,
					data: { message: 'Request rejected', type: 'success' },
				})
			},
			onJoinedFailure
		)
	}

	const removeGuest = () => {
		setSubmitting(true)
		updateSubscription(
			{
				eventId: event.id,
				email,
				status: eventStatus.GUEST_REMOVED,
			},
			(res) => {
				setSubmitting(false)
				alert.set({
					show: true,
					data: { message: 'Guest removed.', type: 'success' },
				})
			},
			(err) => {
				setSubmitting(false)
				alert.set({
					show: true,
					data: { message: 'Request failed, try again', type: 'error' },
				})
			}
		)
	}

	const resendInvite = () => {
		setSubmitting(true)
		updateSubscription(
			{
				eventId: event.id,
				email,
				status: event.status,
				resentInvite: resentInvite++,
			},
			(res) => {
				setSubmitting(false)
				alert.set({
					show: true,
					data: { message: 'Invite sent', type: 'success' },
				})
			},
			(err) => {
				setSubmitting(false)
				alert.set({
					show: true,
					data: {
						message: 'Request to send invite failed, try again',
						type: 'error',
					},
				})
			}
		)
	}

	const onSuccessfulJoined = (res) => {
		setSubmitting(false)
		alert.set({
			show: true,
			data: { message: 'Request accepted', type: 'success' },
		})
	}

	const onJoinedFailure = (err) => {
		setSubmitting(false)
		alert.set({
			show: true,
			data: { message: 'Request failed try again', type: 'error' },
		})
	}

	const handleShare = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.SHARE,
				title: 'Are you sure you want to share contribution details?',
				message: `Sharing these details will allow the selected guest to see the funds as they come in.`,
				participant,
			},
		})
	}

	const handleUnShare = () => {
		modal.set({
			open: true,
			data: {
				id: modalID.SHARE,
				title: 'Unshare Screen with user',
				message: `Are you sure you want to unshare event screen to this user`,
				participant,
			},
		})
	}

	return (
		<div className="flex items-center h-12 px-5 relative">
			<div className="mr-5 flex justify-even items-center">
				<div
					className={`${participantStatus} rounded-full align-middle h-4 w-4 ml-10 `}
				></div>
				{enableShare && isHost ? (
					<div className="text-base ml-5">
						<Input
							checked={participant.share ? true : false}
							handleChange={!participant.share ? handleShare : handleUnShare}
							name="isAgreement"
							type="checkbox"
						/>
					</div>
				) : null}
			</div>

			<div className="basis-1/2">
				<p className="text-left align-left text-gray-500 text-sm">
					{guestDisplayName || email}
				</p>
			</div>

			{(isHost && participant?.invited && total_donation > 0) ||
			eventObject?.share ? (
				<div className="relative w-2/6">
					{donationMade && (
						<>
							<div
								className={`absolute top-4 left-4 h-6 w-6 overflow-visible bg-secondary-500 rounded-full ${
									donationMade ? 'bubble-small' : ''
								}`}
							></div>
							<div
								className={`absolute right-5 h-3 w-3 overflow-visible bg-secondary-500 rounded-full ${
									donationMade ? 'bubble-small' : ''
								}`}
							>
								{' '}
							</div>
						</>
					)}

					<div
						className={`m-auto flex overflow-visible bg-secondary-500 rounded-full align-left h-8 w-8 p-5 justify-center items-center p-5 ${
							donationMade ? 'animate-bubble' : ''
						}`}
						onAnimationEnd={() => setDonationMade(false)}
					>
						<p className="text-center text-white text-sm ">
							${donationAmount.toFixed(2)}
						</p>
						{donationMade && <div className="bubble-small"></div>}
					</div>
				</div>
			) : null}

			{isHost && (
				<div className="absolute right-0 top-0">
					<button
						className="text-gray-400 hover:text-gray-800 focus:outline-none "
						onClick={() =>
							setShowMenu(
								showMenu === participant.email ? null : participant.email
							)
						}
					>
						<svg
							className="w-4 h-4"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h16m-7 6h7"
							></path>
						</svg>
					</button>
					{showMenu === participant.email && (
						<div className="absolute right-0 mt-2 py-2 w-72 bg-white rounded-md shadow-xl z-20">
							{isHost && !participant?.invited ? (
								<>
									<a
										disabled={submitting}
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
										onClick={acceptRequest}
									>
										{submitting ? <FiLoader size={'25'} /> : 'Accept Request'}
									</a>
									<a
										disabled={submitting}
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
										onClick={rejectRequest}
									>
										{submitting ? <FiLoader size={'25'} /> : 'Reject Request'}
									</a>
								</>
							) : (
								<>
									<a
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
										onClick={participant.share ? handleUnShare : handleShare}
									>
										{participant.share ? 'Unshare' : 'Share'}
									</a>
									{participant.status !== eventStatus.GUEST_REMOVED && (
										<a
											className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
											onClick={removeGuest}
										>
											Remove
										</a>
									)}

									<a
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
										onClick={resendInvite}
									>
										Resend Invite
									</a>
								</>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}
