import React from 'react'

export default function Logo() {
	return (
		// <svg
		// 	width="60"
		// 	height="60"
		// 	viewBox="0 0 60 60"
		// 	xmlns="http://www.w3.org/2000/svg"
		// 	xmlnsXlink="http://www.w3.org/1999/xlink"
		// >
		// 	<image href={`${process.env.PUBLIC_URL}/dynamo_cash.png`} height="60" width="60" />
		// </svg>
		<>
			<img
				src={`${process.env.PUBLIC_URL}/dynamo_cash.png`}
				className="h-24 p-4"
				alt="logo"
			/>
		</>
		// <svg width="200" height="200"
		//      xmlns="http://www.w3.org/2000/svg">
		//   <image href="/holly-react/logo.png" height="200" width="200"/>
		// </svg>
		// <img alt="dynamo cash logo" src="/holly-react/logo.png" />
	)
}
