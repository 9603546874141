import React from "react";

const SidebarButton = ({label, onClick, className, disabled = false}, children) => {
  return (
   
    <button
      disabled={disabled}
      className={`bg-inset font-normal text-white py-4 px-5 rounded-none ${disabled?"text-inset2":""} ${className}`}
      onClick={onClick}
    >
      {label}
     
    </button>
   
  )
}

export default SidebarButton
