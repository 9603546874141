import React, { useState, useEffect } from 'react'
import {
	fetchEventByUserEmail,
	updateSubscription,
} from '../../services/subscriptions'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'

const SpentAmountScreen = ({ eventId, status, total_donation }) => {
	const { user } = useHookstate(store)
	const [totalDonation, setTotalDonation] = useState(0)
	const [showAmount, setShowAmount] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	console.log(
		'___ get amount spent status',
		eventId,
		status,
		total_donation,
		totalDonation
	)

	useEffect(() => {
		if (total_donation !== null) {
			setTotalDonation(total_donation)
		}
	}, [total_donation])

	const getAmountSpent = () => {
		if (status !== 'ended' && totalDonation === 0) {
			// Replace with your fetchEventByUserEmail and updateSubscription calls
			console.log('Fetching event data...', eventId)
			// Example usage:
			fetchEventByUserEmail(
				eventId,
				user.email.get(),
				(event) => {
					console.log('display event data...', event)
					const amount = event.donation ? event?.donation?.total_donation : 0
					setTotalDonation(amount)
					setShowAmount(true)
				},
				(error) => {
					console.log('Error retrieving events: ', error)
				}
			)
		} else if (status === 'ended' && totalDonation == 0) {
			console.log('Updating donation...')
			// Replace with your fetchEventByUserEmail and updateSubscription calls
			// Example usage:
			fetchEventByUserEmail(
				eventId,
				user.email.get(),
				(event) => {
					const { donation = {} } = event
					const { total_donation = 0 } = donation

					setTotalDonation(total_donation)
					setShowAmount(true)
					_updateAmountSpent(eventId, total_donation)
				},
				(error) => {
					console.log('Error retrieving events: ', error)
				}
			)
		}
	}

	const _updateAmountSpent = (eventId, totalDonation) => {
		if (totalDonation > 0) {
			setIsLoading(true)
			// Replace with your updateSubscription call
			console.log('Updating donation...')
			// Example usage:
			updateSubscription(
				{
					eventId: eventId,
					email: user.email.get(),
					amountSpent: totalDonation,
				},
				(data) => {
					console.log('Donation updated: ', data)
					setIsLoading(false)
				},
				(error) => {
					console.log('Error updating donation: ', error)
				}
			)
		}
	}

	return (
		<div className="row justify-content-between">
			{showAmount || total_donation ? (
				<div>
					<span>$ {totalDonation}</span>
					<span className="ml-10 text-orange-500"> SPENT</span>
				</div>
			) : (
				<a
					onClick={getAmountSpent}
					className="p-2 rounded-none text-gray-500 text-sm my-5 underline hover:no-underline"
				>
					{isLoading ? '...' : 'View amount'}
				</a>
			)}
		</div>
	)
}

export default SpentAmountScreen
