import PropTypes from 'prop-types'
import React from 'react'
import DatePicker from './date-picker'

const EditModeDatePicker = ({
	readOnly,
	errorMessage,
	onSelect,
	required,
	value,
	allowPastDate,
	placeholder,
	excludeDateFrom,
	reset,
}) => {
	if (readOnly) {
		return <>{value}</>
	}
	return (
		<div className="w-full">
			<DatePicker
				reset={reset}
				placeholder={placeholder}
				allowPastDate={allowPastDate}
				value={value}
				errorMessage={errorMessage}
				required={required}
				onSelect={onSelect}
				excludeDateFrom={excludeDateFrom}
			/>
		</div>
	)
}

EditModeDatePicker.defaultProps = {
	value: '',
	excludeDateFrom: '',
	placeholder: 'Select Date',
	required: false,
	onSelect() {},
	errorMessage: '',
	readOnly: false,
}

EditModeDatePicker.propTypes = {
	value: PropTypes.string,
	excludeDateFrom: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	onSelect: PropTypes.func,
	errorMessage: PropTypes.string,
	readOnly: PropTypes.bool,
}

export default EditModeDatePicker
