const TableHeader = ({ columns, headerStyle }) => {
	const style = 'border-b p-4  pb-3 text-orange-400 text-xl text-left'
	return (
		<thead className="  ">
			<tr className="">
				<td class={style}>Type</td>
				<td class={style}>Event Name</td>
				<td class={style}>Date and Time</td>
				<td class={style}>Attendees</td>
				<td class={style}>Amount</td>
			</tr>
		</thead>
	)
}

export default TableHeader
