import React from 'react'
import PropTypes from 'prop-types'
import { FiCheck,FiX, FiLoader } from 'react-icons/fi'

const InLineInput = ({
                 placeholderForEmpty,
                 readOnly,
                  value,
                 placeholder,
                 onChange,
                 handleChange,
                 errorMessage,
                 multiline,
                 showCount,
                 className,
                 onClick,
                 submitting,
                 cancelAction,
                 ...rest
               }) => {
  if (readOnly) {
    return (
      <>
        {value}
      </>
    )
  }
  const multilineTextBox =
    <textarea
      {...rest}
      aria-placeholder={placeholder}
      className="resize-none w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
      placeholder={placeholder}
      onChange={handleChange}
    />
  const singleLineTextBox = (
    <div className="flex">
      <input
        {...rest}
        aria-placeholder={placeholder}
        className={`w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic ${className}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
      <div className='-ml-20 flex items-center justify-center'>

        <button disabled={submitting}  className={`p-0 mr-5 ${
              submitting ? 'bg-orange-200' : 'bg-orange-300'
            }`}  type='submit' onClick>{submitting?<FiLoader size={"25"}  />:<FiCheck size={"25"}  />}</button>
        <button className='p-0' onClick={cancelAction}><FiX size={"25"}/></button>

      </div>
      {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}
    </div>)
  return (
    <>
      {multiline ? multilineTextBox : singleLineTextBox}
    </>
  )
}

InLineInput.defaultProps = {
  value: '',
  placeholder: '',
  onChange() {
  },
  handleChange() {
  },
  type: 'text',
  errorMessage: '',
  name: '',
  multiline: false,
  readOnly: false
}

InLineInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default InLineInput;
