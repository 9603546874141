import {customStyles} from "../../../utils/select-util";
import Select from 'react-select'
import PropTypes from "prop-types";
import React from "react";
import GooglePlacesAutocomplete, {geocodeByAddress} from "react-google-places-autocomplete";

const key = 'AIzaSyBKu-ZrxkaJIZkM9hqKnCy1MdlfnXJ_F7k'

const PlacesTypeAhead = ({readOnly, errorMessage, onSelect, placeholder, selectedOption, date, value}) => {

  
  if (readOnly) {
    return (
      <>
        {selectedOption?.value}
      </>
    )
  }

  const onChange = (s) => {
    const getGeocode = async () => {
      const geocodeDetails = await geocodeByAddress(s.label)
      if (geocodeDetails.length > 0) {
        const lat = geocodeDetails[0].geometry.location.lat()
        const lng = geocodeDetails[0].geometry.location.lng()
        const tzResponse = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${date?.unixTimestamp}&key=${key}`)
        const tzDetails = await tzResponse.json()
        const {status, ...others} = tzDetails
        s['locationDetails'] = {
          lat,
          lng,
          ...others
        }
      }
      onSelect(s)
    }
    getGeocode()
  }

  return (
    <div className="w-full">
      <GooglePlacesAutocomplete
        
        selectProps={{
          styles: customStyles(),
          placeholder: placeholder,
          value,
          onChange
        }}
        apiKey={key} />

      {errorMessage && (
        <div className="text-red-500 text-xs">
          {errorMessage}
        </div>
      )}

    </div>
  )
}

PlacesTypeAhead.defaultProps = {
  selectedOption: null,
  placeholder: '',
  onChange() {
  },
  errorMessage: '',
  readOnly: false
}

PlacesTypeAhead.propTypes = {
  selectedOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool
}

export default PlacesTypeAhead;
