/*eslint-disable*/
import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import Time from '../time'

export default function CountDown({ days, hours, minute, seconds }) {
	const { user } = useHookstate(store)

	return (
		<div className="flex flex-col items-center my-4">
			<p className="text-sm mx-5 font-medium text-gray-500  md:text-1xl">
				Event Countdown
			</p>
			<div className="grid grid-cols-4 gap-2">
				<Time time={days} string="Days" />
				<Time time={hours} string="Hrs" />
				<Time time={minute} string="Mins" />
				<Time time={seconds} string="Secs" />
			</div>
		</div>
	)
}
