import { getFunctions, httpsCallable } from 'firebase/functions'
import { ErrorHandler, removeEmptyOrNullValues } from '../utils/service-utils'
import store from '../store/store'
import { retrieveEvent, retrieveEventById } from './events'
import { getDatabase, onValue, ref, update } from 'firebase/database'
import { deSanitizedEmail, sanitizedEmail } from '../utils/email-util'
import {
	collection,
	query,
	getDocs,
	getDoc,
	doc,
	getFirestore,
	orderBy,
	where,
	addDoc,
	setDoc,
	updateDoc,
} from 'firebase/firestore'
import { once } from 'lodash'
import { app, firestoreRef } from '../routes'
import { applyFiltersAndPagination, getStatuses } from '../utils/event-utils'
import { getQuery } from '../utils/firestore-util'
import { eventStatus } from '../constants'

// Function to check if an email already has a subscription to an event
export const subscribeToEvent = async (data, onSuccess, onFailure) => {
	try {
		const { eventId, email } = data

		const isExist = await checkExistingSubscription(email, eventId)
		if (isExist) {
			if (onFailure) {
				onFailure('Email has already been added')
			}
			return
		}
		const event = await retrieveEventById(eventId, data)

		if (email === event.email) {
			// Do nothing, you can't subscribe to your own event.
			return
		}

		const subscriptionData = await saveSubscription(
			eventId,
			event.uid,
			event,
			data
		)

		if (onSuccess) {
			onSuccess(subscriptionData)
		}
	} catch (error) {
		console.error('Error during subscription:', error)
		// Handle errors as needed
		if (onFailure) {
			onFailure(error)
		}
	}
}

const checkExistingSubscription = async (email, eventId) => {
	if (!email || !eventId || email.length === 0) {
		throw new Error('invalid-argument', 'Email and eventId are required!')
	}

	const subscriptionQuery = query(
		collection(firestoreRef, 'user-event-subscriptions'),
		where('email', '==', email),
		where('eventId', '==', eventId)
	)

	const subscriptionSnapshot = await getDocs(subscriptionQuery)

	if (subscriptionSnapshot.size > 0) {
		return true
		// throw new Error('already-exists', 'You have already joined this event!')
	} else return false
}

// Function to save a subscription to an event
const saveSubscription = async (eventId, userId, event, others) => {
	const eventsCollection = collection(firestoreRef, 'user-event-subscriptions')
	const docRef = doc(eventsCollection)

	const eventSubscription = {
		...others,
		eventId,
		unixTimestamp: event.unixTimestamp,
		eventName: event.eventName,
		eventType: event.eventType,
		event,
		eventOwner: event.uid,
		createdAt: new Date(),
		eventSubscriptionId: docRef.id,
		createdBy: userId,
		displayName: event.eventName,
	}

	await setDoc(docRef, eventSubscription, { merge: true })

	const { uid, ...rest } = eventSubscription // Remove uid for security reasons

	return rest
}

// Function to batch subscribe to an event
export const batchSubscribeToEvent = async (data, onSuccess, onFailure) => {
	try {
		const { emailList, event, eventId, ...others } = data

		for (let index in emailList) {
			const email = emailList[index]

			try {
				const isExist = await checkExistingSubscription(email, eventId)
				if (isExist) {
					console.log('Email exist', email)
					break
				}
				console.log('Email does not exist', email)
				const subscriptionData = await saveSubscription(
					eventId,
					event.uid,
					event,

					{
						email,
						eventId,
						status: event.status,
						...others,
					}
				)
			} catch (e) {
				console.error(`Error while adding ${email}`)
				// Handle the error as needed
			}
		}

		if (onSuccess) {
			onSuccess()
		}
	} catch (error) {
		console.error('Error during batch subscription:', error)
		// Handle errors as needed
		if (onFailure) {
			onFailure(error)
		}
	}
}

export const retrieveSubscriptions = async (data, onSuccess, onFailure) => {
	try {
		const { email } = data
		const filter = data?.options?.filter || {}
		const criteria = filter.criteria || []
		const eventId = data.options?.eventId
		const nowAndFuture = filter.nowAndFuture || false
		const status = filter.status || 'active'
		const statuses = getStatuses(status)

		let subscriptionsQueryOptions = []

		if (statuses) {
			subscriptionsQueryOptions.push(where('status', 'in', statuses))
		}

		if (criteria.length > 0) {
			subscriptionsQueryOptions.push(getQuery(criteria, []))
		} else {
			subscriptionsQueryOptions.push(orderBy('unixTimestamp', 'desc'))
		}

		let subscriptionsCollectionQuery

		if (eventId) {
			subscriptionsCollectionQuery = query(
				collection(firestoreRef, 'user-event-subscriptions'),
				where('eventId', '==', eventId),
				...subscriptionsQueryOptions
			)
		} else {
			subscriptionsCollectionQuery = query(
				collection(firestoreRef, 'user-event-subscriptions'),
				where('email', '==', email),
				...subscriptionsQueryOptions
			)
		}

		const querySnapshot = await getDocs(subscriptionsCollectionQuery)
		const events = []
		for (const doc of querySnapshot.docs) {
			const data = doc.data()
			console.log(
				'___got here 2',
				data.event.eventName,
				data.status,
				data.event.date
			)
			const eventObject = await retrieveEventById(data.eventId)
			console.log(
				'___got here eventObject',
				eventObject.eventName,
				eventObject.status,
				eventObject.date
			)
			if (eventObject.status !== 'inactive') {
				console.log('___got here eventObject 1', eventObject)
				events.push({
					...eventObject,
					eventSubscriptionId: data.eventSubscriptionId,
					subscriberUid: data.subscriberUid,
					displayName: data.displayName,
				})
			}
		}

		console.log('__QUERY__3 ', ...events)
		// Merge the events into the store or handle them as needed
		store.events.merge(events)

		if (onSuccess) {
			onSuccess()
		}
	} catch (error) {
		throw error
	}
}

export const retrievePaginatedSubscriptions = async (
	data,
	onSuccess,
	onFailure
) => {
	const getStatuses = (status) => {
		// Implement your logic to determine statuses based on the provided status value.
		// This is just a placeholder, modify it to fit your use case.
		if (status === 'active') {
			return [
				'active',
				'started',
				'joined',
				'ended',
				'left_event',
				'inLobby',
				'request_invite',
				'request_accepted',
			]
		} else if (status === 'inactive') {
			return ['inactive']
		} else {
			return ['active', 'inactive', 'started']
		}
	}
	try {
		const { email } = data
		const filter = data?.options?.filter || {}
		const criteria = filter.criteria || []
		const eventId = data.options?.eventId
		const nowAndFuture = filter.nowAndFuture || false
		const status = filter.status || 'active' // Default status
		const statuses = getStatuses(status)

		const subscriptionsCollection = collection(
			firestoreRef,
			'user-event-subscriptions'
		)

		// Create base filters
		let filters = []

		if (eventId) {
			filters.push(where('eventId', '==', eventId))
		} else {
			filters.push(where('email', '==', email))
		}

		// Apply status filter
		filters.push(where('event.status', 'in', statuses))

		// Apply criteria filters using applyFiltersAndPagination function
		const filtersWithCriteria = getQuery(criteria, filters)

		// Create orderBy clause for applyFiltersAndPagination
		const orderByClause = orderBy('unixTimestamp', 'desc')

		// Retrieve paginated data using the reusable function
		const { data: subscriptionsData, next: nextToken } =
			await applyFiltersAndPagination(
				subscriptionsCollection,
				filtersWithCriteria,
				orderByClause,
				10, // Change the limit as needed
				null, // Start after
				null // searchTerm
			)
		const events = []

		// Fetch event data for each subscription
		for (const subscription of subscriptionsData) {
			const eventObject = await retrieveEventById(subscription.eventId)

			if (eventObject.status !== 'inactive') {
				events.push({
					...eventObject,
					eventSubscriptionId: subscription.eventSubscriptionId,
					subscriberUid: subscription.subscriberUid,
					displayName: subscription.displayName,
					amountSpent: eventObject?.amountEarned,
				})
			}
		}
		// Merge the events into the store or handle them as needed
		events.sort((a, b) => {
			const dateA = new Date(a.date)
			const dateB = new Date(b.date)

			return dateB - dateA
		})
		// Merge the events into the store or handle them as needed
		store.subscriptions.data.merge(events)
		store.subscriptions.loading.set(false)
		if (onSuccess) {
			onSuccess()
		}
	} catch (error) {
		store.subscriptions.loading.set(false)
		throw error
	}
}

export const retrieveSubscriptionsByDate = (data, onSuccess, onFailure) => {
	const retrieveSubscriptionsByDate = httpsCallable(
		getFunctions(),
		'subscriptions-retrieveSubscriptionsByDate'
	)

	retrieveSubscriptionsByDate(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			for (let d = 0; d < data.length; d++) {
				retrieveEvent(data[d].eventId, (eventObject) => {
					if (eventObject.status !== 'inactive') {
						store.subscriptions.data.merge([
							{
								...eventObject,
								eventSubscriptionId: data[d].eventSubscriptionId,
								subscriberUid: data[d].subscriberUid,
								displayName: data[d].displayName,
							},
						])
					}
				})
			}

			if (onSuccess) {
				onSuccess()
			}
		})
		.catch((error) => {
			ErrorHandler(error, onFailure)
		})
}

// Function to get a subscription by eventId and userEmail
export const getEventSubscriptionByEventIdAndUserEmail = async (
	email,
	eventId
) => {
	try {
		if (!email || !eventId) {
			throw new Error('Email and eventId are required!')
		}

		const subscriptionsCollection = collection(
			firestoreRef,
			'user-event-subscriptions'
		)
		const q = query(
			subscriptionsCollection,
			where('email', '==', email),
			where('eventId', '==', eventId)
		)
		const querySnapshot = await getDocs(q)

		let subscriptionEvents = null

		if (!querySnapshot.empty) {
			console.log('____SNAP__', querySnapshot)
			querySnapshot.forEach((doc) => {
				subscriptionEvents = doc.data()
			})
		}

		return subscriptionEvents
	} catch (error) {
		console.error('Error fetching subscription:', error)
		throw error
	}
}

// Function to update a subscription document
export const updateSubscription = async (data, onSuccess, onFailure) => {
	try {
		// Prevent updates to data.uid and data.createdAt
		const { displayName, eventId, email, ...others } = data

		const userid = store.user.uid.get()

		const { eventSubscriptionId } =
			await getEventSubscriptionByEventIdAndUserEmail(email, eventId)

		const subscriptionRef = doc(
			firestoreRef,
			'user-event-subscriptions',
			eventSubscriptionId
		)
		const subscriptionSnapshot = await getDoc(subscriptionRef)

		if (!subscriptionSnapshot.exists()) {
			throw new Error('Subscription does not exist!')
		}

		const subscriptionData = subscriptionSnapshot.data()

		if (
			userid !== subscriptionData.subscriberUid &&
			userid !== subscriptionData.eventOwner
		) {
			throw new Error('This user cannot update this subscription!')
		}

		const updatedSubscription = {
			...others,
			updatedAt: new Date(),
			updatedBy: userid,
		}

		if (displayName) {
			updatedSubscription.displayName = displayName
		}

		await updateDoc(subscriptionRef, updatedSubscription)
		const { event, events } = store
		events[event.selectedEvent.get()].merge(data)
		if (onSuccess) {
			onSuccess(data)
		}
		// return eventSubscriptionId
	} catch (error) {
		console.error('Error updating subscription:', error)
		throw error
	}
}

export const fetchParticipant = async (eventId, onSuccess) => {
	const db = getDatabase()

	const query = ref(db, `eventSubscriptions/${eventId}`)
	return onValue(query, (snapshot) => {
		const data = snapshot.val()
		const arr = []
		const attendees = []
		let contribution = 0
		if (snapshot.exists()) {
			console.log(data)
			Object.keys(data).forEach((k) => {
				const v = data[k]

				if (v['status'] != eventStatus.REQUEST_REJECTED) {
					arr.push({ email: deSanitizedEmail(k), ...v })
				}

				if (v['donation'] != null) {
					contribution += v['donation']['total_donation']
				}

				if (v.joined) {
					attendees.push({ email: deSanitizedEmail(k), ...v })
				}
			})
		}
		console.log('**', arr)
		store.participants.set(arr)
		store.contribution.set(contribution)
		store.attendees.set(attendees)
		if (onSuccess) {
			onSuccess()
		}
	})
}

export const getAmountEarnedAndAttendees = async (eventId, callback) => {
	const db = getDatabase()

	const query = ref(db, `eventSubscriptions/${eventId}`)
	return onValue(query, (snapshot) => {
		const data = snapshot.val()
		let amountEarned = 0
		const arr = []
		const attendees = []
		if (snapshot.exists()) {
			console.log(data)
			Object.keys(data).forEach((k) => {
				const v = data[k]
				if (v.donation) {
					amountEarned = amountEarned + v.donation.total_donation
				}
				if (v.joined) {
					attendees.push({ email: deSanitizedEmail(k), ...v })
				}
				arr.push({ email: deSanitizedEmail(k), ...v })
			})
		}
		console.log('**Amount Earned **', amountEarned, arr.length)
		callback({
			amountEarned,
			invitedGuest: arr.length,
			attendees: attendees.length,
		})
	})
}

export const fetchEventByUserEmail = (eventId, userEmail, callback) => {
	try {
		console.log('fetch realtime data called >>>>', eventId, userEmail)
		const db = getDatabase()
		const query = ref(
			db,
			`eventSubscriptions/${eventId}/${sanitizedEmail(userEmail)}`
		)
		return onValue(query, (snapshot) => {
			if (snapshot.exists()) {
				const data = snapshot.val()
				console.log('fetch realtime data>>>>', data)
				store.eventDetail.set({ email: userEmail, ...data })
				if (callback) callback({ email: userEmail, ...data })
			} else {
				store.eventDetail.set({})
			}
		})
	} catch (error) {
		console.log('fetch realtime data error >>>>', error)
	}
}

export const updateSubscriptionInRealTimeDatabase = (
	eventId,
	userEmail,
	data
) => {
	store.modal.set({ open: false })
	store.isLoading.set(true)

	const db = getDatabase()
	return update(
		ref(db, `eventSubscriptions/${eventId}/${sanitizedEmail(userEmail)}`),
		data
	)
}

export const subscriptionInRealTimeDonate = async (
	eventId,
	userEmail,
	data
) => {
	const db = getDatabase()
	return update(
		ref(db, `eventSubscriptions/${eventId}/${sanitizedEmail(userEmail)}`),
		data
	)
}
