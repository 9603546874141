import React, { useState } from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import PaymentACHCard from '../PaymentACHCard'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import {
	retrievePaymentMethods,
	updatePaymentMethod,
} from '../../services/funds'
import Loader from '../loader'
import ConfirmationModal from './confirmation-modal'

function OpenPaymentMethodModal({ opened, title, type, addPaymentMethod }) {
	const { PaymentMethod, modal } = useHookstate(store)
	const [confirmation, setConfirmation] = useState(false)
	const [selectedBank, setSelectedBank] = useState({})
	const { all, loadingPayment } = PaymentMethod.get()
	const [isLoading, setIsLoading] = useState(false)

	const confirmationMessage =
		(selectedBank && selectedBank['defaultCredit'] == true) ||
		selectedBank['defaultDebit'] == true
			? 'when the default payment is being deleted that no financial transaction will be possible without a payment method'
			: 'Are you sure you want to delete this bank'
	// Replace with your logic to retrieve link token
	const retrieveLinkToken = async () => {
		// Implementation for retrieving link token
	}

	const processPaymentMethods = async (data, type, successCallback) => {
		setIsLoading(true)
		await updatePaymentMethod({
			data,
			onSuccess: async (message) => {
				// set previous default to false
				if (successCallback) {
					await retrievePaymentMethods()
					successCallback()
				}
			},
			onError: (message) => {
				setIsLoading(false)
			},
		})
	}

	const getBalance = (paymentMethod) => {
		let balance = ''
		if (paymentMethod && Object.keys(paymentMethod).length > 0) {
			const { balances = {} } = paymentMethod
			const balanceNumber = balances['available'] || balances['current'] || 0
			balance = balanceNumber.toString()
		}
		return balance
	}

	const deleteBank = () => {
		processPaymentMethods(
			{
				id: selectedBank['id'],
				active: false,
			},
			type,
			() => {
				setSelectedBank({})
				modal.open.set(false)
				setIsLoading(false)
				setConfirmation(false)
			}
		)
	}

	// Your implementation here, use state variables like 'all' and 'linkConfiguration'
	console.log('Modal opened with title:', title, 'and type:', type)

	// Example modal content
	const modalContent = (
		<div>
			{/* Your modal content */}
			<div>
				<button
					onClick={async () => {
						addPaymentMethod()
						// Call your PlaidLink.open here
					}}
				>
					Add new Payment Method
				</button>
			</div>

			{confirmation && (
				<ConfirmationModal
					title={'Delete Bank'}
					text={confirmationMessage}
					opened={confirmation}
					onClose={() => setConfirmation(false)}
					disableAction={() => setConfirmation(false)}
					enableAction={deleteBank}
				/>
			)}

			{/* Your PaymentACHCard component */}
			{all.map((paymentMethod, index) => {
				console.log('___Funds Method', paymentMethod)
				let hidden = ''
				if (paymentMethod['defaultDebit'] == true && type === 'debit') {
					hidden = 'hidden' // Blue background color class
				} else if (
					paymentMethod['defaultCredit'] == true &&
					type === 'credit'
				) {
					hidden = 'hidden' // Green background color class
				}
				return (
					<div
						key={index}
						className="flex flex-col items-center justify-center mb-5 mt-5 border-2 border-gray-200 rounded p-4 w-full "
					>
						{/* Your PaymentACHCard component */}
						<PaymentACHCard
							paymentMethod={paymentMethod}
							type={type}
							guestBalance={getBalance(paymentMethod)}
						/>

						{/* Your button collection */}
						<div className={`flex flex-row justify-between ${hidden}`}>
							{/* Delete action */}
							<button
								disabled={isLoading}
								className={`mt-4 bg-orange-500 text-white py-2 px-4 rounded ml-4 ${
									isLoading && 'opacity-50 cursor-not-allowed'
								}}`}
								onClick={() => {
									console.log(`Delete action for index ${index}`)
									setSelectedBank(paymentMethod)
									setConfirmation(true)
								}}
							>
								Delete
							</button>

							{/* Select action */}
							<button
								disabled={isLoading}
								className={`mt-4 bg-orange-500 text-white py-2 px-4 rounded ml-4 ${
									isLoading && 'opacity-50 cursor-not-allowed'
								}}`}
								onClick={() => {
									console.log(`Select action for index ${index}`)
									processPaymentMethods(
										{
											id: paymentMethod['id'],
											defaultDebit: type === 'debit',
											defaultCredit: type === 'credit',
										},
										type,

										() => {
											modal.open.set(false)
											setIsLoading(false)
										}
									)
								}}
							>
								Select
							</button>
						</div>
					</div>
				)
			})}
		</div>
	)

	const header = (
		<div className="p-6  flex flex-row items-center justify-center text-5xl bg-orange-400 text-white w-full">
			<div className=" w-full font-bold text-white text-3xl">{title}</div>
		</div>
	)
	return (
		<Modal
			header={header}
			opened={opened}
			icon={<GrClose onClick={() => modal.open.set(false)} />}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center p-10 overflow-auto h-[75vh]">
				{!loadingPayment ? (
					modalContent
				) : (
					<div className="flex flex-col items-center justify-center min-h-[400px]">
						<Loader style={''} />
					</div>
				)}
			</div>
		</Modal>
	)
}

OpenPaymentMethodModal.propTypes = {}

OpenPaymentMethodModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
}

export default OpenPaymentMethodModal
