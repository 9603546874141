import React, { useEffect, useState } from 'react'
import { useHookstate } from '@hookstate/core'
import store from '../../store/store'
import CardHostEvent from '../../components/cards/card-host-event'
import CardManageEvents from '../../components/cards/card-manage-events'
import EventDetails from '../../components/event-details-card/event-details'
import { retrieveEvents } from '../../services/events'
import DeleteModal from '../../components/modal/delete-modal'
import { deleteEvent } from '../../services/events'
import { useAlert } from 'react-alert'
import { TabHeader } from '../../components/card-tab/tab-header'
import { TabBody } from '../../components/card-tab/tab-body'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { FaMoneyCheck } from 'react-icons/fa'

export default function Home() {
	const { user, event, events, isEventDeleted } = useHookstate(store)
	const emailVerified = user.emailVerified.get()
	const { selectedEvent } = event.get()
	const eventObject = events[selectedEvent]?.get()
	const alert = useAlert()
	const [tab, setTab] = useState()
	useEffect(() => {
		event.loading.set(true)
		if (isEventDeleted.get() === false) {
			retrieveEvents({
				filter: { nowAndFuture: true },
				uid: user.uid.get(),
				email: user.email.get(),
			})
		}
	}, [user.email.get(), tab])

	const handleDelete = () => {
		const event = events[selectedEvent].get()
		deleteEvent(
			{
				id: event.id,
				status: 'inactive',
				eventName: event.eventName,
				uid: event.uid,
				isDeleted: true,
				deletedAt: new Date(),
				deletedBy: user.uid.get(),
			},
			(event) => {
				alert.show('Event Successfully Deleted', { type: 'success' })
			}
		)
	}

	return (
		<div className="container p-4 flex  flex-col items-center justify-center">
			<DeleteModal
				opened={isEventDeleted.get()}
				text={`Are you sure you want to delete ${
					eventObject?.displayName || eventObject?.eventName
				}  event`}
				title="Delete"
				actionStatus={false}
				handleDelete={handleDelete}
				onClose={() => isEventDeleted.set(false)}
			/>
			{!emailVerified && (
				<div className="w-full text-center">Please Verify Your email</div>
			)}

			<div className="container p-4 flex  flex-col items-start justify-center w-full">
				<TabHeader
					items={[
						{
							title: 'Host Event',
							description: 'Create a new event',
							icon: (
								<img
									src={require('../../assets/img/host-event-icon.png')}
									width={50}
									height={50}
								/>
							),
							key: 'host-event',
						},
						{
							title: 'Manage Event',
							description: 'Manage created events',
							icon: (
								<img
									src={require('../../assets/img/manage-event.png')}
									width={50}
									height={50}
								/>
							),
							key: 'manage-event',
						},
					]}
					setTab={setTab}
					tab={tab}
				/>
				<TabBody
					items={{
						'host-event': <CardHostEvent setTab={setTab} />,
						'manage-event': (
							<div className="flex w-full md:max-w-[1216px] -mx-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
								<div className=" ">
									<CardManageEvents />
								</div>
								<div className="">
									<EventDetails />
								</div>
							</div>
						),
					}}
					tab={tab}
				/>
			</div>
		</div>
	)
}
