import store, { initial } from "../store/store";

export function removeEmptyOrNullValues(data) {
  Object.keys(data).forEach(key => {
    if (data[key] === '') {
      delete data[key];
    }
  });
}

export function ErrorHandler(error,onFailure){
  // Getting the Error details.
  const code = error?.code
  const message = error?.message
  const details = error?.details
  const unauthorized = [401,403]
  console.log(code, message, details)
  if (onFailure) {
    onFailure(error)
  }
  if (unauthorized.includes(code)) {
    store.user.set(initial.user)
		localStorage.clear()
    window.location.replace('/login')
  }
}
